import { getDeviceType } from '../../helpers/browser';
import ImageHelper from '../../helpers/image';
import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import dom from '../../wrapper/DomWrapper';

import { SHAPE_POSITIONS } from './constants';
import { checkIsMobileMode, getBackgroundStyles } from './utils';

class PanelWidget {
  constructor(widget) {
    this.widget = widget;
  }

  init = () => {
    this.settings = JSON.parse(this.widget.dataset.settings);
    this.elBgPicture = dom.getElement('.section_bg-img', this.widget);

    this.resize();

    subscribeToDeviceChanging(this.widget, (device) => this.resize(device));

    if (this.elBgPicture) {
      const panelImage = new ImageHelper(this.elBgPicture);

      panelImage.init();
    }
  };

  resize = (currentDevice) => {
    const {
      itemSize,
      shapeElementsLength,
      position,
      backgroundSize,
      isLargeElemInsideBlock,
    } = this.settings;
    const device = currentDevice || getDeviceType();
    const isMobileMode = checkIsMobileMode({
      shapeElementsLength,
      isLargeElemInsideBlock,
      itemSize,
      device,
    });

    if (isMobileMode && position !== SHAPE_POSITIONS.FILL) {
      dom.addClass(this.widget, 'shape-bg-position');
      dom.addClass(this.widget, `_${position.toLowerCase()}`);
    } else {
      dom.removeClass(this.widget, 'shape-bg-position');
      dom.removeClass(this.widget, `_${position.toLowerCase()}`);
    }

    const bgStyles = getBackgroundStyles({
      position,
      backgroundSize,
      device,
      isMobileMode,
    });

    if (!this.elBgPicture || !bgStyles) return;

    dom.updateStyle(this.elBgPicture, bgStyles);
  };
}

export default PanelWidget;
