import get from 'lodash/get';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';
import { subscribeToDebouncedResizeChanging } from '../../observer/resizeObserver';

import {
  getLanguage,
  getHeight,
} from './utils';
import { getResellerData, getResellerId } from '../../helpers/reseller';

import {
  DEFAULT_LANGUAGE,
  PROMO_BANNER_DESCRIPTION,
  PROMO_BANNER_IMAGE,
  PROMO_BANNER_LINK,
  PROMO_BANNER_TITLE,
} from './constants';

class Promotion extends WidgetWrapper {
  init = async () => {
    this.elBanner = dom.getElement(this.selector);
    this.elBody = dom.getElement('body');

    if (!this.elBanner) return;

    this.resellerId = getResellerId();
    this.language = getLanguage();

    try {
      this.resellerData = await getResellerData(this.resellerId);
      this.handlePromoBanner();
    } catch (error) {
      dom.removeElement(this.elBanner);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // eslint-disable-next-line consistent-return
  handlePromoBanner = () => {
    const promoBanner = get(this.resellerData, ['promoBanner']);

    if (!promoBanner) return dom.removeElement(this.elBanner);

    this.bannerData = promoBanner[this.language] || promoBanner[DEFAULT_LANGUAGE];

    if (!this.bannerData) return dom.removeElement(this.elBanner);

    const elTitle = dom.getElement(`.${PROMO_BANNER_TITLE}`, this.elBanner);
    const elDescription = dom.getElement(`.${PROMO_BANNER_DESCRIPTION}`, this.elBanner);
    const elImageWrapper = dom.getElement(`.${PROMO_BANNER_IMAGE}`, this.elBanner);
    const elLink = dom.hasClass(this.elBanner, PROMO_BANNER_LINK)
      ? this.elBanner
      : dom.getElement(`.${PROMO_BANNER_LINK}`, this.elBanner);

    const bannerElements = {
      elTitle,
      elDescription,
      elLink,
      elImageWrapper,
    };

    this.fillBannerContent(bannerElements);
    const isBottomSticky = !!dom.getElement('.partner-promo');

    if (isBottomSticky) dom.addClass(this.elBody, 'has_promo-bottom');

    dom.show(this.elBanner);

    const isTopSticky = !!dom.getElement('.top-sticky-block');

    if (isTopSticky) {
      // TODO timeout uses for IE before changing positions
      // can be removed after IE11 deprecated status
      setTimeout(() => {
        subscribeToDebouncedResizeChanging(this.elBanner, this.changeElementsPosition);
        this.changeElementsPosition();
      }, 0);
    }
  };

  fillBannerContent = (
    bannerElements = {}
  ) => {
    const {
      description, image, title, url,
    } = this.bannerData;
    const {
      elTitle,
      elDescription,
      elLink,
      elImageWrapper,
    } = bannerElements;

    if (elTitle && title) dom.addText(elTitle, title);

    if (elDescription && description) dom.addText(elDescription, description);

    if (elLink && url) elLink.href = url;

    if (elImageWrapper && image) {
      const elImage = dom.createElement('img');
      const imageClass = this.elBanner.getAttribute('data-image-class') || '';

      dom.addClass(elImage, imageClass);
      elImage.src = image;
      elImageWrapper.appendChild(elImage);
    }
  };

  changeElementsPosition = () => {
    const elHeader = dom.getElement('.main__header');
    const elHeaderTopbar = dom.getElement('.topbar__header');
    const elHeaderFixed = dom.getElement('.header_fixed-wrapper');
    const elTemplate = dom.getElement('.template');
    const elNavBox = dom.getElement('.nav__box-wrap', elHeader);

    const isHeaderFixed = dom.hasClass(elHeaderFixed, 'header_fixed-wrapper');

    const bannerHeight = getHeight(this.elBanner);
    const headerHeight = getHeight(elHeader);
    const headerTopbarHeight = getHeight(elHeaderTopbar);

    dom.updateStyle(elNavBox, { top: `${bannerHeight}px` });

    if (!isHeaderFixed) return;

    if (elHeaderFixed) dom.updateStyle(elHeaderFixed, { top: `${bannerHeight}px` });

    const paddingTop = `${headerHeight + headerTopbarHeight + headerHeight}px`;

    dom.updateStyle(elTemplate, { paddingTop });
  }
}

export default Promotion;
