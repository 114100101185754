// TODO: need update after css implementation
import { IMAGE_RATIO_OPTIONS, IMAGE_SHAPE_OPTIONS } from '../../../../components/Booking/constants';
import parseRootFloat from '../../../helpers/parseRootFloat';
import {
  ASPECT_RATIO,
  BUTTON_LINE_HEIGHT,
  BUTTON_PADDING_VERTICAL,
} from '../constants';

/**
 *
 * @param {number} photoWidth
 * @param {number} imageRatio
 * @returns {number}
 */
const getProductMinHeight = ({
  widgetWidth = 0,
  imageShape = IMAGE_SHAPE_OPTIONS.CIRCLE,
  imageRatio = IMAGE_RATIO_OPTIONS.SQUARE_1,
}) => {
  const aspectRatioFloat = ASPECT_RATIO[imageRatio] || 1;
  const textFontSize = parseRootFloat('--text_fontSize');
  const textLineHeight = parseRootFloat('--text_lineHeight');
  const priceFontSize = parseRootFloat('--title_subHeading_fontSize');
  const priceLineHeight = parseRootFloat('--title_subHeading_lineHeight');
  const buttonFontSize = parseRootFloat('--btn_primary_fontSize');

  const textHeight = textFontSize * textLineHeight;

  const heights = {
    photo: imageShape !== IMAGE_SHAPE_OPTIONS.CIRCLE ? widgetWidth / aspectRatioFloat : 0,
    name: textHeight,
    shortDescription: textHeight,
    description: textHeight,
    price: priceFontSize * priceLineHeight,
    duration: priceFontSize * priceLineHeight,
    button: BUTTON_PADDING_VERTICAL + buttonFontSize * BUTTON_LINE_HEIGHT,
  };

  return Object.keys(heights).reduce((height, key) => height + (heights[key] || 0), 0);
};

export default getProductMinHeight;
