import defaultTo from 'lodash/defaultTo';

export default ({
  borderColor,
  isMixedWidth,
  borderTopWidth,
  borderRightWidth,
  borderBottomWidth,
  borderLeftWidth,
  borderWidth,
}) => {
  const borderColorWithDefault = `${defaultTo(
    borderColor,
    'var(--color_brand)'
  )}`;

  return {
    ...!isMixedWidth && {
      borderWidth: `${borderWidth}px`,
    },
    ...isMixedWidth && {
      borderTopWidth: `${borderTopWidth}px`,
      borderRightWidth: `${borderRightWidth}px`,
      borderBottomWidth: `${borderBottomWidth}px`,
      borderLeftWidth: `${borderLeftWidth}px`,
    },
    borderColor: borderColorWithDefault,
    borderStyle: 'solid',
  };
};
