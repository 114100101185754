export const YOUTUBE_DEFAULT_CONFIG = {
  autopause: 0,
  disablekb: 1,
  rel: 0,
  showinfo: 0,
  resetOnEnd: 1,
  fs: 1,
  iv_load_policy: 3,
  controls: 1,
  mute: 1,
  cc_load_policy: 0,
  autoplay: 1,
  modestbranding: 1,
};

export const VIMEO_DEFAULT_CONFIG = {
  speed: 0,
  portrait: 0,
  title: 0,
  controls: 1,
};