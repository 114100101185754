/**
 *
 * @param {number} id
 * @param {string=} name
 * @param {string=} sku
 * @param {string=} urlFormat
 * @returns {`${string}-p${string}`}
 */
const getDashProductUrl = (id, name, sku, urlFormat) => {
  const prepareName = name || '';
  const prepareSku = sku || '';
  let path = prepareName;

  switch (urlFormat) {
    case 'sku': {
      path = prepareSku;

      break;
    }
    case 'nameSku': {
      path = `${prepareName}-${prepareSku}`;

      break;
    }
    case 'skuName': {
      path = `${prepareSku}-${prepareName}`;

      break;
    }
    default: {
      break;
    }
  }

  return `${path.replace(/[^\dA-Za-z]/g, '-')}-p${id}`;
};

export default getDashProductUrl;
