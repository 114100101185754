class ProductModel {
  constructor(settings) {
    this.settings = settings || {};

    this.id = null;

    this.categoryId = null;

    this.image = null;

    this.name = null;

    this.shortDescription = null;

    this.description = null;

    this.priceFormatted = null;

    this.duration = null;
  }

  get withImage() {
    return !!this.settings.showImage;
  }

  get withName() {
    return !!this.settings.showName && !!this.name;
  }

  get withShortDescription() {
    return !!this.settings.showShortDescription && !!this.shortDescription;
  }

  get withDescription() {
    return !!this.settings.showDescription && !!this.description;
  }

  get withPrice() {
    return !!this.settings.showPrice && !!this.priceFormatted;
  }

  get withDuration() {
    return !!this.settings.showDuration && !!this.duration;
  }

  updateSettings(newSettings) {
    this.settings = {
      ...this.settings,
      ...newSettings,
    };
  }
}

export default ProductModel;
