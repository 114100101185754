import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';

/**
 *
 * @param {Object} data
 * @param {Object} product
 * @param {Object} defaults
 * @returns {{}}
 */
const prepareDataWithMap = (data, product, defaults = {}) => Object.keys(data)
  .reduce((acc, key) => {
    const prepareData = { ...acc };

    const dataValue = data[key];
    const defaultValue = defaults[key];
    let value;

    if (isPlainObject(dataValue)) {
      const { handler, path } = dataValue;

      value = handler(get(product, path), product);
    } else {
      value = get(product, dataValue, defaultValue);
    }

    if (!isNil(value)) prepareData[key] = value;

    return prepareData;
  }, {});

export default prepareDataWithMap;
