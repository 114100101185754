import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import dom from '../../../../../wrapper/DomWrapper';
import { LIGHT_BOX_SELECTOR } from '../../../../Gallery/constants';
import ItemRenderer from '../../../../ItemsView/renderer';
import lazyLoad from '../../../../LazyLoad';
import LightBox from '../../../../Lightbox';

import { CLASS_NAMES, LIGHT_BOX_OPTIONS, REST_IMAGES_MAX_SIZE } from './constants';
import { checkGalleryPagination } from './utils';

class ProductGallery {
  constructor(images, settings) {
    this.elContainer = dom.getElement(`#${settings?.galleryId}`);
    this.renderer = new ItemRenderer(dom.getElement(`#${settings?.templateGalleryId}`), {
      imports: { forEach, isNil },
    });
    this.settings = settings;
    this.lightBox = null;
    this.lightBoxData = null;

    this.state = {
      images: images || [],
      originalImage: null,
      withRestImages: false,
      mapGalleryImages: [],
      restImagesWrapClassName: null,
      galleryPaginationCount: null,
      checkGalleryPagination: null,
      checkGalleryHiddenItem: null,
    };
  }

  setState = (nexState = {}) => {
    this.state = {
      ...this.state,
      ...nexState,
    };

    return this.state;
  };

  init = () => {
    if (!this.elContainer) return;

    this.initLightBox();
    this.initRestGallery();
    this.render();
  };

  update = (images) => {
    this.setState({ images: images || [] });
    this.initRestGallery();
    this.render();
  };

  initLightBox = () => {
    if (this.lightBox) return;

    this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);
  };

  initRestGallery = () => {
    const { showMainImage } = this.settings;
    const { images = [] } = this.state;

    const mapGalleryImages = Object.keys(images)
      .slice(1)
      .reverse()
      .map((key, index) => ({
        src: images[key].imageUrl,
        id: `product-gallery-item-${index + 1}`, // original image has 0 index, rest images start from 1 index
        ...LIGHT_BOX_OPTIONS,
      }));

    const withRestImages = !showMainImage || !isEmpty(mapGalleryImages);

    this.setState({
      originalImage: { src: images[0]?.imageUrl },
      withRestImages,
    });
    this.initGalleryLightBox(mapGalleryImages);

    if (!withRestImages) return;

    this.initRestAdditionalGallery(mapGalleryImages);
  };

  initRestAdditionalGallery = (mapGalleryImages = {}) => {
    const { showMainImage } = this.settings;

    const mapGalleryImagesSize = mapGalleryImages.length;
    const maxGalleryImages = Math.min(mapGalleryImagesSize, REST_IMAGES_MAX_SIZE);
    const restImagesWrapClassName = `${CLASS_NAMES.restImagesWrap}_${showMainImage ? maxGalleryImages : maxGalleryImages + 1}`;

    const paginationSize = mapGalleryImagesSize - REST_IMAGES_MAX_SIZE;

    const fnGalleryPagination = (index) => checkGalleryPagination(index, paginationSize);

    this.setState({
      mapGalleryImages,
      restImagesWrapClassName,
      galleryPaginationCount: paginationSize + 1,
      checkGalleryPagination: fnGalleryPagination,
      checkGalleryHiddenItem: (index) => index >= REST_IMAGES_MAX_SIZE && !fnGalleryPagination(index),
    });
  };

  initGalleryLightBox = (mapGalleryImages = {}) => {
    const { originalImage = {} } = this.state;
    const galleryLightBoxImages = [{ ...originalImage, ...LIGHT_BOX_OPTIONS }, ...mapGalleryImages];

    this.lightBoxData = galleryLightBoxImages.reduce((acc, image, index) => ({
      ...acc,
      [`product-gallery-item-${index}`]: image,
    }), {});
  };

  connectGalleryLightBox = () => {
    const { hash } = this.settings;
    const galleryHash = `product-gallery-${hash}`;
    const galleryList = { [galleryHash]: this.lightBoxData };

    const {
      navButtonsAttributes,
      modalsParams,
    } = this.lightBox.getLightBoxAttributes();

    this.lightBox.galleryModalData[galleryHash] = this.lightBoxData;

    this.lightBox.addLightBoxToGalleryPhotos(
      galleryList,
      navButtonsAttributes,
      modalsParams
    );
  };

  render = () => {
    if (!this.renderer) return;

    this.elContainer.innerHTML = this.renderer.render({ product: this.state });

    if (this.lightBoxData) this.connectGalleryLightBox();

    lazyLoad();
  };
}

export default ProductGallery;
