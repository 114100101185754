import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import booking from '../../../../booking';
import checkProjectWithAnimation from '../../../../helpers/checkProjectWithAnimation';
import { connectInView } from '../../../../helpers/inView';
import dom from '../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../wrapper/WidgetWrapper';
import ItemsRenderer from '../../../ItemsView/renderer';
import { getProductMinHeight } from '../../utils';

import Product from './Product';

class ProductsWidget extends WidgetWrapper {
  init = (parent = dom.document) => {
    const elProductsList = dom.getCollection(this.selector, parent);
    const withAnimation = checkProjectWithAnimation();

    if (isEmpty(elProductsList)) return;

    [...elProductsList].forEach((elProductWrapper = {}) => {
      const { settings: settingsStr } = elProductWrapper.dataset;

      try {
        const settings = JSON.parse(settingsStr);

        this.initProduct(elProductWrapper, settings, withAnimation);
      } catch (error) {
        console.error(error);
      }
    });
  };

  initProduct = (elProductWrapper, settings, withAnimation) => {
    const {
      hash,
      productId,
      imageShape,
      imageRatio,
    } = settings;
    const elProductTemplate = dom.getElement(`#booking-product-template-${hash}`);
    const elProductRetryTemplate = dom.getElement(`#booking-product-retry-${hash}`);

    if (!elProductTemplate) return;

    const renderer = new ItemsRenderer(elProductTemplate, {
      imports: { forEach, isNil },
    });
    const retryRenderer = elProductRetryTemplate ? new ItemsRenderer(elProductRetryTemplate) : null;

    const run = async () => {
      dom.addHtml(elProductWrapper, '');
      dom.addClass(elProductWrapper, 'spinner');

      const widgetWidth = dom.getElementWidth(elProductWrapper);
      const minHeight = getProductMinHeight({
        widgetWidth,
        imageShape,
        imageRatio,
      });

      dom.updateStyle(elProductWrapper, { minHeight: `${minHeight}px` });

      if (!productId) {
        dom.removeClass(elProductWrapper, 'spinner');
        dom.addHtml(elProductWrapper, '');
      }

      try {
        const product = await booking.provider.getProduct(productId, settings);

        dom.removeClass(elProductWrapper, 'spinner');
        dom.updateStyle(elProductWrapper, { minHeight: null });
        dom.addHtml(elProductWrapper, renderer.render({
          product,
        }));
        (new Product(dom.getElement('.booking', elProductWrapper), product).init());

        if (withAnimation) {
          const elProductWidget = dom.getElement('.booking', elProductWrapper);

          connectInView(elProductWidget);
        }
      } catch (error) {
        console.error(error);
        const is404Error = error.message === '404';
        const isHiddenError = error.message === 'hidden';

        dom.removeClass(elProductWrapper, 'spinner');

        if (retryRenderer && !is404Error && !isHiddenError) {
          dom.addHtml(
            elProductWrapper,
            retryRenderer.render({ items: [1], itemClassName: 'gallery-item' })
          );
          dom.on(dom.getElement('._try-btn', elProductWrapper), 'click', run);
        } else {
          dom.addHtml(elProductWrapper, '');
        }
      }
    };

    run();
  };
}

export default ProductsWidget;
