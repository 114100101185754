const DEVICES = ['quad', 'desktop', 'laptop', 'tablet', 'phone'];

const LAYOUT_PADDING = 30;

const FULL_IMAGE_SETTINGS = {
  quad: {
    width: 2850,
  },
  desktop: {
    width: 1410,
  },
  laptop: {
    width: 1170,
  },
};

const CONTENT_IMAGE_SETTINGS = {
  quad: {
    narrow: {
      default: { width: 960 },
      wide: { width: 1200 },
    },
    standard: {
      default: { width: 1200 },
      wide: { width: 1440 },
    },
    wide: {
      default: { width: 1440 },
      wide: { width: 1680 },
    },
  },
  desktop: {
    narrow: {
      default: { width: 800 },
      wide: { width: 1000 },
    },
    standard: {
      default: { width: 1000 },
      wide: { width: 1200 },
    },
    wide: {
      default: { width: 1200 },
      wide: { width: 1400 },
    },
  },
  laptop: {
    narrow: {
      default: { width: 683 },
      wide: { width: 854 },
    },
    standard: {
      default: { width: 854 },
      wide: { width: 1024 },
    },
    wide: {
      default: { width: 1024 },
      wide: { width: 1200 },
    },
  },
  tablet: {
    width: 738,
  },
  phone: {
    width: 350,
  },
};

const GRID_CONTENT_SETTINGS = {
  quad: {
    width: 1410,
    postPerRow: [2, 3, 4, 5, 6],
  },
  desktop: {
    width: 1170,
    postPerRow: [2, 3, 4, 5, 6],
  },
  laptop: {
    width: 994,
    postPerRow: [2, 3, 4, 5, 6],
  },
  tablet: {
    width: 728,
    postPerRow: [1, 2, 2, 3, 3],
  },
  phone: {
    width: 320,
    postPerRow: [1, 1, 2, 2, 2],
  },
};

const IMAGE_ASPECT_RATIO = {
  horizontal: 0.75,
  square: 1,
  vertical: 1.25,
};

module.exports = {
  DEVICES,
  LAYOUT_PADDING,
  FULL_IMAGE_SETTINGS,
  CONTENT_IMAGE_SETTINGS,
  GRID_CONTENT_SETTINGS,
  IMAGE_ASPECT_RATIO,
};
