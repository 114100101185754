const paramsToQueryString = (params) => Object
  .keys(params)
  .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

export default {
  allProducts: (id, token, params) => `https://app.shopsettings.com/api/v3/${id}/products?token=${token}&${paramsToQueryString(params)}`,
  allProductsFromCategory: (id, token, categoryId) => `https://app.shopsettings.com/api/v3/${id}/products?token=${token}&category=${categoryId}`,
  product: (id, token, productId) => `https://app.shopsettings.com/api/v3/${id}/products/${productId}?token=${token}`,
  storeSettings: (id, token) => `https://app.shopsettings.com/api/v3/${id}/profile?token=${token}`,
  category: (id, token, categoryId) => `https://app.shopsettings.com/api/v3/${id}/categories/${categoryId}?token=${token}`,
  categories: (id, token, params) => `https://app.shopsettings.com/api/v3/${id}/categories?token=${token}&${paramsToQueryString(params)}`,
  coupon: (id, token, couponId) => `https://app.shopsettings.com/api/v3/${id}/discount_coupons?token=${token}&code=${couponId}`,
  cart: (id, token, cardId) => `https://app.shopsettings.com/api/v3/${id}/carts/${cardId}?token=${token}`,
};
