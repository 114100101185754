import dom from '../../wrapper/DomWrapper';

const URL_TAGS = [
  'og:type',
  'og:url',
  'twitter:url',
];

/**
 *
 * @param {string=} type
 */
const updateUrl = (type) => {
  const elHead = dom.document.head;
  const { href } = window.location;

  URL_TAGS.forEach((name) => {
    const key = name === 'twitter:url' ? 'name' : 'property';
    const elMeta = dom.getElement(`meta[${key}="${name}"]`);
    const isType = name === 'og:type';
    const content = isType ? type : href;

    if (!content) return;

    if (elMeta) {
      elMeta.setAttribute('content', content);
    } else {
      elHead.insertAdjacentHTML('beforeend', `<meta ${key}="${name}" content="${content}"></meta>`);
    }
  });
};

export default updateUrl;
