import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

/**
 *
 * @param {array|object|string} images
 * @returns {array}
 */
const prepareImages = (images) => {
  if (isArray(images)) return images;
  if (isPlainObject(images)) return [images];

  return [];
};

export default prepareImages;
