import isString from 'lodash/isString';

import { GET_SERVICE_PAGE_REGEX } from '../constants/routes';

/**
 *
 * @param {string} href
 * @param {string} bookingTag
 * @returns {boolean}
 */
const checkServicePage = (href, bookingTag) => {
  if (!isString(href)) return false;

  return GET_SERVICE_PAGE_REGEX(bookingTag).test(href);
};

export default checkServicePage;
