import dom from '../../../wrapper/DomWrapper';
import { MAX_YOUTUBE_THUMBNAIL } from '../constants';
import { checkIsYouTubeThumbnailCorrect } from '../utils';

import ProviderWrapper from './Wrapper';

class VideoYouTube extends ProviderWrapper {
  async init() {
    const {
      id,
      autoplay,
      color,
      removeControls,
      isBackground,
      isSliderBg,
      src,
      fs,
    } = this.dataParams;
    const isExist = await this.checkIsVideoExist(id);

    const isVideoExists = this.renderVideoOnPage(isExist);

    if (isVideoExists) return;

    if (!isExist) return;

    this.player = this.connectVideoDefault();
    this.setYouTubeThumbnail(src);
    this.addPreviewImage(this.player);

    this.player.source = {
      type: 'video',
      sources: [
        {
          src: id,
          provider: 'youtube',
        },
      ],
    };

    this.player.config.youtube = {
      ...this.player.config.youtube,
      autopause: 0,
      color,
      disablekb: 1,
      rel: 0,
      showinfo: 0,
      resetOnEnd: 1,
      fs: this.isFullScreen(isBackground, fs),
      iv_load_policy: 3,
      controls: removeControls || isBackground ? 0 : 1,
      mute: isBackground ? 1 : autoplay,
      cc_load_policy: isBackground && 0,
      autoplay: isBackground && 1,
      modestbranding: isBackground && 1,
      origin: dom.window.location.origin,
    };

    this.player.elements.poster.style.display = 'none';

    this.player.elements.container?.addEventListener('ready', () => {
      if (isBackground || isSliderBg) {
        this.setSizeVideo();
      } else {
        this.resizeVideo();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isFullScreen = (isBackground, fullScreen) => (!isBackground && fullScreen ? 1 : 0);

  setYouTubeThumbnail = (src) => {
    if (!src || !src.includes(MAX_YOUTUBE_THUMBNAIL)) return;

    dom.updateStyle(this.video, { opacity: 0 });

    checkIsYouTubeThumbnailCorrect(src)
      .then((isCorrect) => {
        if (isCorrect) return;

        const hdThumbnail = src.replace(MAX_YOUTUBE_THUMBNAIL, '0');
        const elImage = dom.getElement('img', this.video);

        elImage.dataset.src = hdThumbnail;
        elImage.setAttribute('src', hdThumbnail);
      })
      .finally(() => dom.updateStyle(this.video, { opacity: 1 }));
  };

  // eslint-disable-next-line class-methods-use-this
  checkIsVideoExist = async (id) => {
    if (!id) return false;

    const src = `https://img.youtube.com/vi/${id}/0.jpg`;

    const isExist = await checkIsYouTubeThumbnailCorrect(src);

    return isExist;
  };

  setSizeVideo = async () => {
    try {
      const { id } = this.dataParams;
      const res = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${id}`);
      const { width, height } = await res.json();
      const ratio = width / height;
      const isShort = ratio < 1.5; // magic number (16/9 or 9/16) but minimal width 200.

      if (isShort) {
        const iframe = this.getIframe();

        iframe.height = 640;
        iframe.width = (640 / 16) * 9;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.resizeVideo();
    }
  };
}

export default VideoYouTube;
