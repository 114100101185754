import getBlogMenuItem from './getBlogMenuItem';

const { normalUrl } = require('../../../../../../helper/urls/convertSrcToCorrect');

const getPostFullUrl = (postUrl, menu, isBackup) => {
  if (isBackup) return null;

  const blogMenuItem = getBlogMenuItem(menu);

  return blogMenuItem && typeof postUrl === 'string' ? normalUrl(`/${blogMenuItem.tag}${postUrl}`) : '#';
};

export default getPostFullUrl;
