const isInViewport = (rect) => (
  rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
);

const replaceCalendarPosition = (calendarInstance) => {
  const { calendarContainer } = calendarInstance;
  const rect = calendarContainer.getBoundingClientRect();
  const calendarContainerHeight = rect.height;

  if (!isInViewport(rect)) {
    calendarContainer.style.top = `-${calendarContainerHeight}px`;
  }
};

export default replaceCalendarPosition;
