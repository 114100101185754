const getContentImageTransform = require('./getContentImageTransform');
const getCompactImageTransform = require('./getCompactImageTransform');

/**
 *
 * @param {Object} options
 * @param {string} options.style
 * @param {number} options.aspectRatio
 * @param {string} options.pageWidth
 * @param {{ width: number, height: number}} options.original
 * @returns {Object}
 */
const getListImageTransform = ({
  style,
  aspectRatio,
  pageWidth,
  original,
}) => {
  if (style === 'standard') {
    return getContentImageTransform({
      original,
      aspectRatio,
      pageWidth,
      widthSetting: 'default',
    });
  }

  return getCompactImageTransform({
    original,
    aspectRatio,
    pageWidth,
  });
};

module.exports = getListImageTransform;
