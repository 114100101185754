import {
  DESKTOP,
  LAPTOP,
  PHONE,
  QUAD,
  TABLET,
  ULTRA,
} from '../../../device';

export const IMAGE_V2_CLASS_NAME = 'is-image-v2';
export const IMAGE_RESIZE_CLASS_NAME = 'is-image-resize';
export const IMAGE_SVG_CLASS_NAME = 'is-image-svg';
export const IMAGE_NOT_FIXED_SIZE_CLASS_NAME = 'is-not-fixed-size';
export const DEVICE_SIZES = { // also used in blog and paypal product
  [PHONE]: [0, 567],
  [TABLET]: [568, 1024],
  [LAPTOP]: [1025, 1366],
  [DESKTOP]: [1367, 1920],
  [QUAD]: [1921, null],
};
export const FLUID_DEVICE_SIZES = {
  [QUAD]: [1921, 2560],
  [ULTRA]: [2561, null],
};
