/* eslint-disable camelcase */
const get = require('lodash/get');

const cutNumber = require('../cutNumber');
const { CONTENT_IMAGE_SETTINGS, DEVICES, LAYOUT_PADDING } = require('./constants');
const getFullImageTransform = require('./getFullImageTransform');

/**
 *
 * @param {Object} options
 * @param {number=} options.aspectRatio
 * @param {{ width: number, height: number}} options.original
 * @param {string} options.widthSetting
 * @param {string} options.pageWidth
 * @returns {Object}
 */
const getContentImageTransform = ({
  aspectRatio,
  original,
  widthSetting,
  pageWidth,
}) => {
  if (widthSetting === 'full') return getFullImageTransform({ original });

  const {
    width: originalWidth,
    height: originalHeight,
  } = original || {};

  return DEVICES.reduce((transform, device) => {
    const imageWidth = get(CONTENT_IMAGE_SETTINGS, [device, pageWidth, widthSetting, 'width'])
      || get(CONTENT_IMAGE_SETTINGS, [device, 'width']);
    const wrapWidth = imageWidth - LAYOUT_PADDING;
    const wrapHeight = cutNumber(aspectRatio
      ? wrapWidth * aspectRatio
      : wrapWidth / (originalWidth / originalHeight));

    if (originalWidth < wrapWidth || originalHeight < wrapHeight) return transform;

    return {
      ...transform,
      [`image_${device}`]: {
        wrap_height: wrapHeight,
      },
    };
  }, {});
};

module.exports = getContentImageTransform;
