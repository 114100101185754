import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import Countdown from './Countdown';

class CountdownFactory extends WidgetWrapper {
  init = () => {
    const widgets = dom.getCollection(this.selector);

    [...widgets].forEach((widget) => {
      const instance = new Countdown(widget);

      instance.init();
    });
  };
}

export default CountdownFactory;
