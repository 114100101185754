/**
 *
 * @param {string} hash
 * @param {string} layout
 * @param {Object} options
 * @param {string} options.staticDir
 * @param {string=} options.subDir
 * @returns {string} staticResizeUrl
 */
const getStaticImageResizeUrl = (hash, layout, { staticDir = '/__static', subDir } = {}) => `${staticDir}/${hash}${subDir || ''}/${layout}`;

module.exports = getStaticImageResizeUrl;
