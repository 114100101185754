import isEmpty from 'lodash/isEmpty';

import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import FacebookComments from './FacebookComments';
import FacebookPage from './FacebookPage';

class Facebook extends WidgetWrapper {
  init = () => {
    const elFacebookWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elFacebookWidgetList)) return;

    dom.window?.FB?.init({
      xfbml: true,
      version: 'v17.0',
    });

    elFacebookWidgetList.forEach((element) => {
      if (!element) return;

      const { name } = element.dataset;

      let widget;

      switch (name) {
        case 'comments': {
          widget = new FacebookComments(element);

          break;
        }
        case 'page': {
          widget = new FacebookPage(element);

          break;
        }
        default: {
          widget = new FacebookPage(element);
        }
      }

      widget.init();
    });
  };
}

export default Facebook;
