/* eslint-disable camelcase */
const get = require('lodash/get');
const {
  DEVICES,
  LAYOUT_PADDING,
  GRID_CONTENT_SETTINGS,
} = require('./constants');
const cutNumber = require('../cutNumber');

/**
 *
 * @param {Object} options
 * @param {number} options.rowLimit
 * @param {number} options.aspectRatio
 * @param {{ width: number, height: number}} options.original
 * @returns {Object}
 */
const getGridImageTransform = ({
  rowLimit,
  aspectRatio,
  original,
}) => {
  const {
    width: originalWidth,
    height: originalHeight,
  } = original || {};

  return DEVICES.reduce((transform, device) => {
    const imageWidth = get(GRID_CONTENT_SETTINGS, [device, 'width']);
    const postPerRow = get(GRID_CONTENT_SETTINGS, [device, 'postPerRow', rowLimit - 2]);
    const wrapWidth = cutNumber(((imageWidth + LAYOUT_PADDING) - (postPerRow * LAYOUT_PADDING)) / postPerRow);
    const wrapHeight = cutNumber(wrapWidth * aspectRatio);

    if (originalWidth < wrapWidth || originalHeight < wrapHeight) return transform;

    return {
      ...transform,
      [`image_${device}`]: {
        wrap_height: wrapHeight,
      },
    };
  }, {});
};

module.exports = getGridImageTransform;
