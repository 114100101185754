import updateImage from './updateImage';
import updateTitleDescription from './updateTitleDescription';
import updateUrl from './updateUrl';

/**
 *
 * @param {string=} type
 * @param {string=} name
 * @param {string=} title
 * @param {string=} description
 * @param {Object=} image
 * @param {boolean=} isStaticImagesPublished
 * @param {boolean=} isOriginalImage
 */
const updateMetaTags = ({
  type,
  name,
  title,
  description,
  image,
  isStaticImagesPublished,
  isOriginalImage,
}) => {
  updateUrl(type);

  if (name || title || description) updateTitleDescription({ name, title, description });
  if (image) updateImage({ image, isStaticImagesPublished, isOriginalImage });
};

export default updateMetaTags;
