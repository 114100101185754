import debounce from 'lodash/debounce';

import dom from '../../../wrapper/DomWrapper';
import { LAZY_LOADED_EVENT } from '../../LazyLoad';

/**
 *
 * @param {HTMLElement[]} data
 */
const update = (data) => {
  const [elWrapper, elImage] = data;

  elWrapper.style.removeProperty('height');

  if (dom.hasClass(elWrapper, 'picture-wrap_unavailable')) return;

  const elWrapperHeight = Math.round(elWrapper.offsetHeight);
  const elImageHeight = Math.round(elImage.offsetHeight);
  const isNeedUpdate = elImageHeight < elWrapperHeight;

  if (!isNeedUpdate) return;

  dom.updateStyle(elWrapper, { height: `${elImageHeight}px` });
};

/**
 *
 * @param {[HTMLElement[]]} buffer
 * @returns {(function(): void)|*}
 */
const handleResize = (buffer) => () => {
  for (const data of buffer) {
    update(data);
  }
};

const fixContainImages = () => {
  const collection = dom.getCollection('.picture-wrap_contain.is-image-v2') || [];
  const elements = [...collection];

  if (elements.length === 0) return;

  const buffer = [];

  for (const elWrapper of elements) {
    const elPicture = dom.getElement('picture.lazy', elWrapper);

    if (!elPicture) continue;

    dom.on(elPicture, LAZY_LOADED_EVENT, () => {
      const elImage = dom.getElement('.picture__fill', elPicture);

      if (!elImage) return;

      const data = [elWrapper, elImage];

      update(data);
      buffer.push(data);
    });
  }

  dom.on(dom.window, 'resize', debounce(handleResize(buffer), 34));
};

export default fixContainImages;
