/* eslint-disable camelcase */
/**
 * This util convert id from custom links for correct format
 * F.e. we have custom link https://vimeo.com/mackenziesheppard/moth in this case
 * id equal 'mackenziesheppard/moth' and we need to ask Vimeo API (oEmbed endpoint) to get all data
 * about this video and extract correct format - in our case it will be '314648479'
 * @param {string} id
 * @returns {Promise<string>}
 */
const getVimeoVideoId = async (id) => {
  const url = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`;

  try {
    const response = await fetch(url);

    if (response.status === 200 || response.status === 201) {
      const { video_id } = await response.json();

      return video_id;
    }

    return id;
  } catch {
    return id;
  }
};

export default getVimeoVideoId;
