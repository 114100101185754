const getCoordinatesForAddress = async (address) => {
  const { google } = window;

  if (!google) return;

  const geocoder = new google.maps.Geocoder();

  if (!geocoder) return;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      const { GeocoderStatus } = google.maps;
      const {
        OK,
        ZERO_RESULTS,
      } = GeocoderStatus;

      if (status === OK && status !== ZERO_RESULTS) {
        const { location } = results[0].geometry;
        const latlng = new google.maps.LatLng(location.lat(), location.lng());

        if (latlng) {
          resolve(latlng);
        } else {
          reject(new Error(`Geocoder error ${status}`));
        }
      }
    });
  });
};

const URLtoLatLng = (url) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (!url.includes('http')) {
    return {
      lat: null,
      lng: null,
    };
  }

  // eslint-disable-next-line react/destructuring-assignment
  const latlng = url.slice(Math.max(0, url.indexOf('@') + 1))
    .split(',');

  return {
    lat: latlng[0],
    lng: latlng[1],
  };
};

const checkIfAddressIsURL = (address) => address?.includes('http');

const getCoordinatesForURL = (url) => {
  const {
    lat,
    lng,
  } = URLtoLatLng(url);

  const { google } = window;

  const latlng = new google.maps.LatLng(lat, lng);

  return latlng;
};

export {
  getCoordinatesForAddress,
  getCoordinatesForURL,
  checkIfAddressIsURL,
};
