import isElement from 'lodash/isElement';

import dom from '../../../wrapper/DomWrapper';
import defaultImage from '../utils';

import errorHandler from './errorHandler';
import pictureErrorHandler from './pictureErrorHandler';

const ERROR_CLASS_NAME = 'picture-wrap_unavailable';
const ERROR_TEMPLATE = `<div class="${ERROR_CLASS_NAME}" />`;

const insertErrorTemplate = (elWrapper) => {
  if (!isElement(elWrapper) || dom.getElement(`.${ERROR_CLASS_NAME}`, elWrapper)) return;

  elWrapper.insertAdjacentHTML('beforeend', ERROR_TEMPLATE);
};

const onLoad = (elImage, elParent, getElWrapper) => {
  const currentSrc = elImage?.currentSrc;
  const elWrapper = getElWrapper(elParent);

  if (!(isElement(elWrapper))) return;

  if (currentSrc === defaultImage) {
    insertErrorTemplate(elWrapper);

    return;
  }

  dom.removeElement(dom.getElement(`.${ERROR_CLASS_NAME}`, elWrapper));
};

const onError = (elImage, elParent, getElWrapper) => {
  pictureErrorHandler(elImage, elParent, () => {
    const elWrapper = getElWrapper(elParent);

    insertErrorTemplate(elWrapper);
  });
};

const previewErrorHandler = () => {
  const selectorName = '.blog-item__thumbnails-img > picture';

  const getElWrapper = (elParent) => {
    const elGridThumb = elParent.closest('[data-grid-image]');

    if (!elGridThumb) return;

    const { gridImage } = elGridThumb.dataset;
    const elErrorSelector = gridImage === 'background' ? '.blog-item__overlay' : '.blog-item__thumbnails-img';

    return dom.getElement(elErrorSelector, elGridThumb);
  };

  errorHandler(selectorName, (elImage, elParent) => {
    onError(elImage, elParent, getElWrapper);
  }, (elImage, elParent) => {
    onLoad(elImage, elParent, getElWrapper);
  });
};

const heroErrorHandler = () => {
  const selectorName = '.post-hero .post-page__picture > picture';
  const getElWrapper = (elParent) => elParent.closest('.post-page__picture');

  errorHandler(selectorName, (elImage, elParent) => {
    onError(elImage, elParent, getElWrapper);
  }, (elImage, elParent) => {
    onLoad(elImage, elParent, getElWrapper);
  });
};

const contentErrorHandler = () => {
  const selectorName = '.blog-feed__editor .picture__wrap picture';
  const getElWrapper = (elParent) => elParent.closest('.picture__wrap');

  errorHandler(selectorName, (elImage, elParent) => {
    pictureErrorHandler(elImage, elParent, () => {
      const elWrapper = getElWrapper(elParent);

      if (!(isElement(elWrapper))) return;

      dom.addClass(elWrapper, ERROR_CLASS_NAME);
    });
  }, (elImage, elParent) => {
    const currentSrc = elImage?.currentSrc;
    const elWrapper = getElWrapper(elParent);

    if (!(isElement(elWrapper))) return;

    if (currentSrc === defaultImage) {
      dom.addClass(elWrapper, ERROR_CLASS_NAME);

      return;
    }

    dom.removeClass(elWrapper, ERROR_CLASS_NAME);
  });
};

export default () => {
  previewErrorHandler();
  heroErrorHandler();
  contentErrorHandler();
};
