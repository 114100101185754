import get from 'lodash/get';

import dom from '../../../wrapper/DomWrapper';

import ProviderWrapper from './Wrapper';

const runQueueFunction = () => {
  const { queueInitDMVideo } = window;

  if (!queueInitDMVideo) {
    return;
  }

  for (const element of queueInitDMVideo) {
    element();
  }

  window.queueInitDMVideo = [];
};

const downloadDMScript = (runAfterDownloadDM) => {
  if (window.isLoadingDMScript) {
    window.queueInitDMVideo.push(runAfterDownloadDM);

    return;
  }

  window.isLoadingDMScript = true;
  window.dmAsyncInit = runQueueFunction;
  window.queueInitDMVideo = [runAfterDownloadDM];
  const el = document.createElement('script');

  el.src = 'https://api.dmcdn.net/all.js';
  el.async = true;
  const nodeScript = document.querySelectorAll('script')[0];

  nodeScript?.parentNode?.insertBefore(el, nodeScript);
};

class VideoDailymotion extends ProviderWrapper {
  init(props) {
    this.isPostList = get(props, 'isPostList', false);

    if (dom.window.DM) {
      this.initPlayer();
    } else {
      downloadDMScript(this.initPlayer);
    }
  }

  initPlayer = () => {
    this.player = this.connectDailymotionPlayer();
    this.addPreviewImage(this.player);
  };

  connectDailymotionPlayer() {
    const {
      id, hash, autoplay, fs, isBackground,
    } = this.dataParams;
    const controls = this.isPostList ? 1 : fs;

    const elVideoContainer = dom.getElement(`#v-${hash}`, this.video);

    // fix for video inside the slide, because DM reinit video if find equal #id
    if (this.video.closest('.slick-cloned')) elVideoContainer.id += '-cloned';

    this.player = dom.window.DM.player(elVideoContainer, {
      video: id,
      width: '100%',
      height: '100%',
      params: {
        autoplay: !!autoplay,
        mute: isBackground ? true : !!autoplay,
        controls: controls || autoplay,
        queue: false,
        'sharing-enable': false,
      },
    });

    if (isBackground) {
      if (this.player) this.onPlayerPlayBack();

      dom.on(this.player, 'playback_ready', this.onPlayerPlayBack);
    }

    dom.on(this.player, 'playing', this.onPlayerPlaying);

    dom.on(this.player, 'video_end', this.onPlayerEnded);

    return this.player;
  }

  onPlayerPlayBack = async () => {
    const { id } = this.dataParams;

    try {
      const fetchData = await fetch(`https://api.dailymotion.com/video/${id}?fields=width%2Cheight%2Caspect_ratio`);
      const { height, width } = await fetchData.json();

      this.player.height = height;
      this.player.width = width;
    } catch (error) {
      console.error(error);
    } finally {
      this.resizeVideo();
    }
  };

  onPlayerPlaying = () => {
    const isBackground = get(this, ['dataParams', 'isBackground'], false);
    const autoplay = get(this, ['dataParams', 'autoplay'], false);

    this.hidePreview();

    if (!isBackground && !autoplay) return;

    const timeout = this.getLoopTimeoutTime();

    this.loopTimeoutId = setTimeout(this.playSingleVideo, timeout);
  };

  playSingleVideo = () => {
    const startTime = get(this, ['dataParams', 'videoParams', 't']);
    const startTimeInSec = this.getTimeCode(startTime);

    this.player.seek(startTimeInSec || 0);
  };
}

export default VideoDailymotion;
