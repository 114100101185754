import isString from 'lodash/isString';

import { GET_CART_PAGE_DASH_REGEX, GET_CART_PAGE_REGEX } from '../constants';

const isCartPage = (href, storeUrl, isDashStore) => {
  if (!isString(href)) return false;

  if (isDashStore) {
    return GET_CART_PAGE_DASH_REGEX(storeUrl).test(href);
  }

  return GET_CART_PAGE_REGEX(storeUrl).test(href);
};

export default isCartPage;
