import WidgetWrapper from '../../../../../wrapper/WidgetWrapper';

import ItemsView from '../../../../ItemsView/ItemsView';

class CatalogPage extends WidgetWrapper {
  init = () => {
    (new ItemsView('.catalog-page .ecom-catalogue[data-widget="itemsView"]')).init();
  };
}

export default CatalogPage;
