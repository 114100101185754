import has from 'lodash/has';

import {
  DESKTOP, LAPTOP, PHONE, QUAD, TABLET,
} from '../../device';

import checkImageTransform from './checkImageTransform';

const DEFAULT_LAYOUTS = [`image_${QUAD}`, `image_${DESKTOP}`, `image_${LAPTOP}`, `image_${TABLET}`, `image_${PHONE}`];

/**
 *
 * @param {Object} transform
 * @param {string[]=} layouts
 * @returns {boolean} isFullTransform
 */
const checkImageFullTransform = (transform, layouts = DEFAULT_LAYOUTS) => checkImageTransform(transform)
  && layouts.every((layout) => has(transform, layout));

export default checkImageFullTransform;
