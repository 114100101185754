import isEmpty from 'lodash/isEmpty';

import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import PayPalProductItem from './PaypalProductItem';

class PayPalProduct extends WidgetWrapper {
  init = () => {
    this.elPaypalProducts = dom.getCollection(this.selector);

    if (isEmpty(this.elPaypalProducts)) return;

    const widget = new PayPalProductItem();

    this.elPaypalProducts.forEach((selector) => {
      widget.init(selector);
    });
  };
}

export default PayPalProduct;
