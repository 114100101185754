import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import Disqus from '../../Disqus';
import { DISQUS_SELECTOR, DISQUS_NAME } from '../../../constants';
import FacebookComments from '../../Facebook/FacebookComments';
import { convertStringToDom } from './utils';

const disqusTemplate = (postId, disqusAccount) => (
  `<div
    class="post-page__comments js-widget"
    data-widget="disqus"
    data-post-id="${postId}"
    data-disqus-account="${disqusAccount}"
    style="opacity:0;"
  >
    <div id="disqus_thread"></div>
  </div>`
);

const facebookCommentsTemplate = (data) => {
  const {
    classes, colorscheme, numPosts, orderBy,
  } = data;

  return `<div class="post-page__comments" style="pointer-events:none;">
        <div class="${classes}" data-widget="facebook" data-name="comments">
      <div
        class="fb-comments"
        data-width="100%"
        data-colorscheme="${colorscheme}"
        data-numposts="${numPosts}"
        data-href="${window.location.href}"
        data-order-by="${orderBy}"
        data-skin="${colorscheme}"
        data-lazy="true"
      >
      </div>
    </div>
  </div>`;
};

class Comments extends WidgetWrapper {
  getContent = (data) => {
    const {
      postId,
      countPerPage = 10,
      sortBy = 'newest',
      colorscheme = 'light',
      postCommentsProvider,
      premiumFeatureClass = null,
      disqusAccount,
      postPageComment,
      postPageCommentGlobal,
    } = data;

    const showPostComments = postPageComment && postPageCommentGlobal;

    if (!showPostComments) {
      return '';
    }

    if (postCommentsProvider === DISQUS_NAME) {
      return disqusTemplate(postId, disqusAccount);
    }

    const { href } = window.location;
    const classes = `facebook_like facebook_comments spinner js-widget${premiumFeatureClass ? ' premiumFeatureClass' : ''}`;
    const dataComment = {
      href,
      orderBy: sortBy,
      numPosts: countPerPage,
      colorscheme,
      classes,
    };

    return facebookCommentsTemplate(dataComment);
  };

  init = (provider, postDomNode) => {
    if (provider === DISQUS_NAME) {
      const disqusInstance = new Disqus(DISQUS_SELECTOR);

      disqusInstance.init();
    } else {
      const facebookCommentsNode = postDomNode.querySelector('.facebook_comments');
      const facebookCommentsInstance = new FacebookComments(facebookCommentsNode);

      if (window.FB) {
        facebookCommentsInstance.init();

        return;
      }

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: '1076920205677536',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v9.0',
        });

        facebookCommentsInstance.init();
      };

      const FBScript = '<script defer src="https://connect.facebook.net/en_US/sdk.js"></script>';
      const FBScriptDom = convertStringToDom(FBScript);

      document.body.appendChild(FBScriptDom);
    }
  };
}

export default Comments;
