/* eslint-disable camelcase */

const mapIconUrl = 'https://storage.googleapis.com/wzreponsiveeditor-static-latest/images/maps';

const DEFAULT_MAP_CENTER_COORDINATES = { lat: 40.861458, lng: -73.891037 }; // New York, Park Avenue

const MARKER_ICONS = {
  default: `${mapIconUrl}/default_red.svg`,
  default_green: `${mapIconUrl}/default_green.svg`,
  default_blue: `${mapIconUrl}/default_blue.svg`,
  default_orange: `${mapIconUrl}/default_orange.svg`,
  arts: `${mapIconUrl}/arts.svg`,
  bars: `${mapIconUrl}/bars.svg`,
  camera: `${mapIconUrl}/camera.svg`,
  coffee: `${mapIconUrl}/coffee.svg`,
  convenience: `${mapIconUrl}/convenience.svg`,
  dining: `${mapIconUrl}/dining.svg`,
  flag: `${mapIconUrl}/flag.svg`,
  home: `${mapIconUrl}/home.svg`,
  salon: `${mapIconUrl}/salon.svg`,
  shopping: `${mapIconUrl}/shopping.svg`,
};

const DEFAULT_MAP_ZOOM = 15;
const MAX_MAP_ZOOM = 21;

export {
  MARKER_ICONS,
  DEFAULT_MAP_CENTER_COORDINATES,
  DEFAULT_MAP_ZOOM,
  MAX_MAP_ZOOM,
};
