import isNil from 'lodash/isNil';

import { PAGE_TYPES } from '../../../../components/Navigation/constants';
import browser from '../../../helpers/browser';
import dom from '../../../wrapper/DomWrapper';
import {
  HEADER_NAV,
  MENU_DEFAULT_CLASS,
  MENU_HOVER_CLASS,
  MENU_WRAPPER_CLASS,
  NAV_LINK_CLASS,
} from '../constants';

/** *
 *
 * @param {Object} options
 * @param {Element} options.elHeader
 * @param {boolean} options.isCollapsed
 * @returns {{init: function, update: function}}
 */
export default function MobileNav({
  elHeader,
  isCollapsed = false,
}) {
  this.elHeader = elHeader;
  this.isCollapsed = isCollapsed;

  const resetHovers = () => {
    const elHeaderNav = dom.getElement(HEADER_NAV, this.elHeader);
    const elNavFolderLinks = dom.getCollection(`.${NAV_LINK_CLASS}[data-type=${PAGE_TYPES.FOLDER}]`, elHeaderNav);

    if (isNil(elNavFolderLinks)) return;

    [...elNavFolderLinks].forEach((el) => {
      const elLinkWrapper = el.closest(`.${MENU_DEFAULT_CLASS}`);

      dom.removeClass(elLinkWrapper, MENU_HOVER_CLASS);
    });
  };

  const setHover = (el) => {
    const elLinkWrapper = el.closest(`.${MENU_DEFAULT_CLASS}`);

    dom.addClass(elLinkWrapper, MENU_HOVER_CLASS);
  };

  const init = () => {
    if (!browser.isMobile()) return;

    // orientationchange - reset hovers
    dom.on(dom.window, 'orientationchange', () => {
      resetHovers();
    });

    // click outside of menu - reset hovers
    dom.on(dom.document, 'click', (e) => {
      if (this.isCollapsed || !e.target) return;

      if (e.target.closest(`.${MENU_WRAPPER_CLASS}`) || dom.hasClass(e.target, MENU_WRAPPER_CLASS)) return;

      resetHovers();
    });

    // click on folder link - reset hovers and add hover to target
    dom.on(this.elHeader, 'click', (e) => {
      if (this.isCollapsed || !e.target) return;

      const isNalLink = dom.hasClass(e.target, NAV_LINK_CLASS);
      const elParentLink = e.target.closest(`.${NAV_LINK_CLASS}`);

      if (!isNalLink && !elParentLink) return;

      const elLink = isNalLink ? e.target : elParentLink;
      const isElNavFolderLink = elLink.dataset.type === PAGE_TYPES.FOLDER;

      if (!isElNavFolderLink) return;

      e.preventDefault();

      resetHovers();
      setHover(elLink);
    });
  };

  const update = ({
    isCollapsed: nextIsCollapsed,
  }) => {
    if (!isNil(nextIsCollapsed)) {
      this.isCollapsed = nextIsCollapsed;
    }
  };

  return {
    init,
    update,
  };
}
