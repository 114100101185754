import isElement from 'lodash/isElement';
import dom from '../wrapper/DomWrapper';

/**
 * Util for create image element with url and preload it
 * @param {string} url
 * @return {Promise<HTMLElement|Error>}
 */
const createImage = (url) => new Promise((resolve, reject) => {
  const elImage = dom.createElement('img');

  if (!isElement(elImage)) {
    reject(new Error('image create error'));

    return;
  }

  elImage.onload = (e) => {
    resolve(e.target);
  };

  elImage.onerror = (e) => {
    reject(e);
  };

  elImage.src = url;
});

export default createImage;
