import isArray from 'lodash/isArray';
import { DESKTOP } from '../../../../../device';

/**
 * Calc actual limit for current device and total rows
 * @param {Object} options
 * @param {string} options.device
 * @param {number} options.totalRow
 * @param {{
 *   quad: [number],
 *   desktop: [number],
 *   laptop: [number],
 *   tablet: [number],
 *   phone: [number],
 * }} options.rows
 * @returns {number} actualLimit
 */
const getActualLimit = ({
  device = DESKTOP,
  totalRow = 0,
  rows = {},
}) => {
  const deviceRows = rows[device];

  if (!isArray(deviceRows)) return 0;

  return deviceRows.slice(0, totalRow).reduce((acc, curr) => acc + curr);
};

export default getActualLimit;
