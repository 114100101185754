import SelectWidget from 'choices.js';
import format from 'date-fns/format';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isElement from 'lodash/isElement';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import set from 'lodash/set';

import { ERROR_MESSAGES } from '../../../components/Form/constants';
import { i18next } from '../../../i18n';
import getStateValue from '../../helpers/getStateValue';
import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import { checkContrastBg } from './helpers/checkContrastBg';
import removeExtraSpaces from './helpers/removeExtraSpaces';
import {
  BTN_AUTO_CLOSE_SELECTOR,
  CAPTCHA_ERROR_ACTIONS,
  CAPTCHA_ERROR_CLASS,
  CAPTCHA_ERROR_TYPES,
  FIELDS_TYPE,
  FORM_AUTO_CLOSE_CLASS,
  FORM_ERROR_SELECTOR,
  FORM_MESSAGE_CLASS,
  FORM_SPINNER_CLASS,
  FORM_SUCCESS_SELECTOR,
  FORM_TOOLTIP_SELECTOR,
  INPUT_DESCRIPTION_SELECTOR,
  INPUT_ERROR_ACTIVE_CLASS,
  INPUT_ROW_CLASS,
  INPUT_TEXT_ERROR_CLASS,
  INPUT_WRAPPER_CLASS,
  INPUT_WRAPPER_ERROR_CLASS,
  MESSAGE_TIMEOUT,
  ONE_COLUMN_CLASS,
  SELECT_CONFIG,
  SHOW_TOOLTIP_CLASS,
  TOOLTIP_TIMEOUT,
  VALIDATORS,
} from './constants';
import Datepickers from './Datepickers';
import supportedInputTypes from './helpers';

class Form extends WidgetWrapper {
  constructor(selector) {
    super(selector);

    this.state = {
      formNames: {},
      values: {},
      defaultValues: {},
      errors: {},
      errorMessages: {},
      validationRules: {},
      needReset: {},
      recaptcha: {},
      datepickers: {},
      isAvailableCopyPromo: true,
      formType: {},
    };

    this.datepickers = new Datepickers(this.state.values, this.validateHandler);
    this.projectId = getStateValue('projectId', '');
    this.embedApiService = getStateValue(['apiUrls', 'embedApiService'], false);
    this.forms = [...dom.getCollection(this.selector)];

    window.onloadRecaptchaCallback = this.onloadRecaptchaCallback;
  }

  static getFieldType = (type) => {
    switch (type) {
      case FIELDS_TYPE.TEXTAREA:
      case FIELDS_TYPE.SUBJECT: {
        return FIELDS_TYPE.TEXTAREA;
      }
      case FIELDS_TYPE.SELECT: {
        return FIELDS_TYPE.SELECT;
      }
      case FIELDS_TYPE.ROW: {
        // for new case with split Select SP-248677
        return `${FIELDS_TYPE.INPUT}, ${FIELDS_TYPE.SELECT}`;
      }
      default: {
        return FIELDS_TYPE.INPUT;
      }
    }
  };

  static isFieldRequired = (
    inputId,
    rules = this.state.validationRules
  ) => get(
    rules,
    [inputId, 'validationRules', 'required'],
    false
  );

  init = () => {
    this.forms.forEach((form) => {
      // find form fields by hashes
      const {
        widgetId: widgetIdRaw,
        widgetFormName,
        widgetValidationRules: widgetValidationRulesRaw,
        widgetSubmit: widgetSubmitRaw,
        widgetErrorMessages: errorMessageListRaw,
      } = form.dataset;

      const widgetId = JSON.parse(widgetIdRaw);
      const widgetValidationRules = JSON.parse(widgetValidationRulesRaw);
      const widgetSubmit = JSON.parse(widgetSubmitRaw);
      const widgetErrorMessageList = JSON.parse(errorMessageListRaw);

      const submitButton = dom.getElement('._submit  button', form);
      const promocodeField = dom.getElement('.form-success__promo', form);

      dom.on(submitButton, 'click', (e) => {
        e.preventDefault();
        this.onSubmitHandler(
          this.state.values[form.id],
          form,
          widgetId,
          widgetSubmit
        );
      });

      if (promocodeField) {
        dom.on(promocodeField, 'click', (e) => {
          e.preventDefault();
          this.copyPromoToClipboard({ promocodeField, form });
        });
      }

      const { formType } = widgetSubmit;

      this.state.formNames[form.id] = widgetFormName;
      this.state.validationRules[form.id] = widgetValidationRules;
      this.state.errorMessages[form.id] = widgetErrorMessageList;
      this.state.formType[form.id] = formType;

      const isOneColumn = this.checkIsOneColumnForm(form);

      // add logic for every input
      Object.keys(widgetId).forEach((id) => {
        // get field wrapper by id
        const fieldWrapper = dom.getElement(`[id="${id}"]`, form);

        if (fieldWrapper) {
          const { fieldtype, split } = fieldWrapper.dataset;
          const inputWrap = dom.getElement('.input-wrap', fieldWrapper);
          const inputDescription = dom.getElement(INPUT_DESCRIPTION_SELECTOR, fieldWrapper);
          const error = dom.createElement('div');

          error.setAttribute('class', INPUT_ERROR_ACTIVE_CLASS);

          if (split) {
            this.createSplittedForm(fieldWrapper, isOneColumn);
          } else if (inputWrap) {
            inputWrap.append(error.cloneNode(true));

            if (inputDescription) inputWrap.append(inputDescription);
          }

          // get fields inside wrapper according to field type
          const fields = dom.getCollection(Form.getFieldType(fieldtype), fieldWrapper);
          const createField = fieldtype === FIELDS_TYPE.SELECT
            ? this.createSelectField
            : this.createTextField;

          createField(fields, form, id, widgetId);
        }
      });

      checkContrastBg(form);
    });
  };

  createSplittedForm = (elFieldWrapper, isOneColumn = false) => {
    const error = dom.createElement('div', { className: INPUT_ERROR_ACTIVE_CLASS });
    const builder = isOneColumn
      ? this.createExpandedRow
      : this.createSplicedRow;

    builder(elFieldWrapper, error);
  };

  createSplicedRow = (elFieldWrapper, error) => {
    const elErrorsWrapper = dom.createElement('div', { className: 'flex' });
    const inputs = dom.getCollection('.input-wrap', elFieldWrapper);
    const isSplicedFieldName = this.checkIsSplicedNameRow(elFieldWrapper);
    const options = this.getFieldOptions(elFieldWrapper);
    const isRequire = get(options[0], 'validationRules.required', false);

    if (isEmpty(inputs)) return;

    inputs.forEach((input) => {
      const elCurrentInputDescription = dom.getElement(INPUT_DESCRIPTION_SELECTOR, input);
      const elCurrentErrorWrapper = dom.createElement('div', { className: 'flex-inner' });

      elCurrentErrorWrapper.append(error.cloneNode(true));

      if (elCurrentInputDescription && !isSplicedFieldName) {
        elCurrentErrorWrapper.append(elCurrentInputDescription);
      }

      elErrorsWrapper.append(elCurrentErrorWrapper);
    });

    if (isSplicedFieldName) {
      inputs.forEach((input) => {
        const elInputDescription = dom.getElement(INPUT_DESCRIPTION_SELECTOR, input);

        if (isRequire) input.append(error.cloneNode(true));

        if (elInputDescription) {
          input.before(elErrorsWrapper);
        }
      });
    }

    elFieldWrapper.append(elErrorsWrapper);
  };

  showTooltip = (form) => {
    const tooltip = dom.getElement(FORM_TOOLTIP_SELECTOR, form);

    if (!tooltip) return;

    const handleClose = () => {
      dom.removeClass(form, SHOW_TOOLTIP_CLASS);
      this.state.isAvailableCopyPromo = true;
    };

    dom.addClass(form, SHOW_TOOLTIP_CLASS);
    this.state.isAvailableCopyPromo = false;
    setTimeout(handleClose, TOOLTIP_TIMEOUT);
  };

  copyPromoToClipboard = async ({ promocodeField, form }) => {
    const { isAvailableCopyPromo } = this.state;

    if (!promocodeField || !isAvailableCopyPromo) return;

    const text = promocodeField.textContent;

    if (window.navigator?.clipboard) {
      await window.navigator.clipboard.writeText(text)
        .then(() => {
          this.showTooltip(form);
        })
        .catch((error) => {
          console.error(`Not copied, try again. Error: ${error}`);
        });
    } else {
      console.error('Your Browser does not support clipboard feature');
    }
  };

  createExpandedRow = (elFieldWrapper, error) => {
    const elInputWraps = dom.getCollection(`.${INPUT_WRAPPER_CLASS}`, elFieldWrapper);
    const elFlex = dom.getElement('.flex', elFieldWrapper);
    const isSplicedFieldName = this.checkIsSplicedNameRow(elFieldWrapper);

    if (isEmpty(elInputWraps)) return;

    elInputWraps.forEach((inputWrapper) => {
      const elFormRow = dom.createElement('div', { className: INPUT_ROW_CLASS });
      const elDescription = dom.getElement(INPUT_DESCRIPTION_SELECTOR, inputWrapper);

      if (elDescription) {
        inputWrapper.insertBefore(error.cloneNode(true), elDescription);
      } else {
        inputWrapper.append(error.cloneNode(true));
      }

      elFormRow.append(inputWrapper);
      elFieldWrapper.append(elFormRow);
    });

    if (elFlex) dom.removeElement(elFlex);

    if (isSplicedFieldName) {
      const elNameFieldDescription = dom.getElement(INPUT_DESCRIPTION_SELECTOR, elFieldWrapper);

      if (elNameFieldDescription) elFieldWrapper.append(elNameFieldDescription);
    }
  };

  checkIsSplicedNameRow = (elFieldWrapper) => {
    if (!isElement(elFieldWrapper)) return false;

    const {
      split,
      fieldtype,
    } = elFieldWrapper.dataset;

    return split === 'true' && fieldtype === 'name';
  };

  checkIsOneColumnForm = (element) => {
    if (!element) return false;

    const rowItem = element.closest('.row__item');

    if (!rowItem) return false;

    return dom.hasClass(rowItem, ONE_COLUMN_CLASS);
  };

  // eslint-disable-next-line consistent-return
  showSpinner = (form) => {
    if (!form) return null;

    dom.addClass(form, FORM_SPINNER_CLASS);
    dom.removeClass(form, FORM_MESSAGE_CLASS);
  };

  // eslint-disable-next-line consistent-return
  showMessage = (form, success = true) => {
    if (!form) return null;

    const container = dom.getElement(
      success
        ? FORM_SUCCESS_SELECTOR
        : FORM_ERROR_SELECTOR,
      form
    );
    const isAutoClose = dom.hasClass(container, FORM_AUTO_CLOSE_CLASS);
    const handleClose = () => {
      dom.removeClass(form, FORM_MESSAGE_CLASS);
      dom.hide(container);
    };

    dom.show(container);
    dom.addClass(form, FORM_MESSAGE_CLASS);
    dom.removeClass(form, FORM_SPINNER_CLASS);

    if (isAutoClose) {
      setTimeout(handleClose, MESSAGE_TIMEOUT);
    } else {
      const closeBtn = dom.getElement(BTN_AUTO_CLOSE_SELECTOR, form);

      dom.on(closeBtn, 'click', handleClose);
    }
  };

  getCurrencyValue = (elField) => {
    if (!isElement(elField) || elField.dataset?.fieldtype !== 'currency') return null;

    const [options = {}] = this.getFieldOptions(elField) || [];
    const { currency } = options;

    return currency;
  };

  getInputValue = (key, value = '') => {
    try {
      if (!supportedInputTypes.date) throw new Error('Unsupported  input type');

      // TODO data type founding method
      const currentEl = dom.getElement(`input[title='${key}']`);
      const inputType = currentEl.type;

      if (inputType !== 'date') return value;

      return format(new Date(`${value} 00:00:00`), 'MM/dd/yyyy');
    } catch {
      return value;
    }
  };

  getFormData = (formId, meta = {}) => {
    const formName = get(this, ['state', 'formNames', formId], null);
    const state = get(this, ['state', 'values', formId], null);
    const fields = get(this, ['state', 'validationRules', formId], null);
    const formType = get(this, ['state', 'formType', formId], null);

    const convertedData = state && fields && Object.keys(fields).reduce((data, stateKey) => {
      const updatedData = [...data];
      const [, key] = stateKey.split('_');
      const elField = dom.getElement(`#${key}`);
      const currency = this.getCurrencyValue(elField);
      let value = this.getInputValue(key, state[stateKey]);

      if (!isNil(currency) && !isEmpty(value)) {
        value = `${currency} ${value}`;
      }

      const keyIndexInData = findIndex(updatedData, { key });
      const hasKey = keyIndexInData !== -1;

      if (!hasKey) return [...updatedData, { key, value }];

      const updatedValue = [updatedData[keyIndexInData].value, value];

      updatedData[keyIndexInData] = { key, value: updatedValue };

      return updatedData;
    }, []);

    return JSON.stringify({
      formName, formType, data: convertedData, ...meta,
    });
  };

  getFieldOptions = (field) => {
    if (isNil(field)) return [];

    try {
      const { fieldoptions: fieldOptionsRaw } = field.dataset;
      const fieldOptions = JSON.parse(fieldOptionsRaw);

      return isArray(fieldOptions)
        ? fieldOptions
        : [fieldOptions];
    } catch {
      return [];
    }
  };

  getErrorMessage = (
    formId,
    id,
    options = {},
    isSplicedName = false,
    isSplicedField = false
  ) => {
    try {
      const value = get(this, ['state', 'values', formId, id], null);
      const customValidationText = get(options, ['validationText']);
      const initialValidationText = get(options, ['initialValidationText']);
      const messageKey = get(this, ['state', 'errors', formId, id], '');
      let errorMessage = get(this, ['state', 'errorMessages', formId, messageKey], '');

      const isCorrectField = !isSplicedName && (isSplicedField || messageKey !== 'required');

      if (messageKey === 'email') {
        if (value.length <= 5) {
          errorMessage = i18next.t(ERROR_MESSAGES.emailLessLength);
        }
        if (value.length >= 255) {
          errorMessage = i18next.t(ERROR_MESSAGES.emailMaxLength);
        }
      }

      if (isSplicedName) {
        return customValidationText || initialValidationText || errorMessage;
      }

      if (!!customValidationText && isCorrectField) return customValidationText;

      return errorMessage;
    } catch {
      return '';
    }
  };

  validateField = (
    value,
    formId,
    inputId
  ) => {
    const validateRules = get(
      this,
      ['state', 'validationRules', formId, inputId, 'validationRules'],
      false
    );

    if (!validateRules) return false;

    return Object
      .keys(validateRules)
      .reduce((filedError, validationKey) => {
        const validator = get(VALIDATORS, [validationKey], () => true);
        const error = !validator(value);

        if (error) {
          set(
            this,
            `state.errors.${formId}.${inputId}`,
            validationKey || 'default'
          );
        }

        return error || filedError;
      }, false);
  };

  validateFormData = (form, fieldIdList) => {
    if (!form || !fieldIdList) return false;

    const formId = form.getAttribute('id');

    return Object.keys(fieldIdList).reduce(
      (formHasError, key) => {
        const fieldId = get(fieldIdList, [key, 'fieldId'], null);
        const inputIds = get(fieldIdList, [key, 'inputIds'], null);

        let error = false;

        inputIds.forEach((inputId) => {
          const value = get(this, ['state', 'values', formId, inputId], null);
          const isRequired = get(
            this,
            ['state', 'validationRules', formId, inputId, 'validationRules', 'required'],
            false
          );

          if (isRequired || (value !== '' && !isNil(value))) {
            error = this.validateHandler(
              value || '',
              inputId,
              fieldId,
              formId
            ) || error;
          }
        });

        return error || formHasError;
      },
      false
    );
  };

  showInputsGroupErrorMessages = (
    formId,
    fieldWrapper,
    inputId
  ) => {
    const inputWrappers = dom.getCollection(`.${INPUT_WRAPPER_CLASS}`, fieldWrapper);
    const isSplicedNameRow = this.checkIsSplicedNameRow(fieldWrapper);
    const fieldOptions = this.getFieldOptions(fieldWrapper);

    if (!inputWrappers || !inputId) return;

    inputWrappers.forEach((inputWrap, index) => {
      if (inputId.startsWith(index)) {
        const errorWrappers = dom.getCollection(`.${INPUT_TEXT_ERROR_CLASS}`, fieldWrapper);

        dom.addClass(inputWrap, INPUT_WRAPPER_ERROR_CLASS);

        // this check is necessary because when isSplicedNameRow we have the same fieldOptions for both fields,
        // but index increases in forEach, and we got fieldOptions[index] === undefined for second of splited field (eg. surname )

        const getIndex = isSplicedNameRow && fieldOptions[index] ? fieldOptions[index] : fieldOptions[index - 1];

        dom.addText(
          errorWrappers[index],
          this.getErrorMessage(formId, inputId, getIndex, isSplicedNameRow, true)
        );
      }
    });
  };

  hideInputsGroupErrorMessage = (
    formId,
    fieldWrapper,
    inputId
  ) => {
    const inputWrappers = dom.getCollection(`.${INPUT_WRAPPER_CLASS}`, fieldWrapper);

    if (has(this, `state.errors.${formId}.${inputId}`)) delete this.state.errors[formId][inputId];
    if (!inputWrappers || !inputId) return;

    inputWrappers.forEach((inputWrap, index) => {
      if (inputId.startsWith(index)) {
        const errorWrappers = dom.getCollection(`.${INPUT_TEXT_ERROR_CLASS}`, fieldWrapper);

        dom.removeClass(inputWrap, INPUT_WRAPPER_ERROR_CLASS);
        dom.addText(errorWrappers[index], '');
      }
    });
  };

  showErrorMessage = (formId, fieldWrapper, inputId) => {
    const inputWrap = dom.getElement(`.${INPUT_WRAPPER_CLASS}`, fieldWrapper);
    const errorWrapper = dom.getElement(`.${INPUT_TEXT_ERROR_CLASS}`, fieldWrapper);
    const fieldOptions = this.getFieldOptions(fieldWrapper);

    dom.addText(errorWrapper, this.getErrorMessage(formId, inputId, fieldOptions[0], false, false));
    dom.addClass(inputWrap, INPUT_WRAPPER_ERROR_CLASS);
  };

  hideErrorMessage = (formId, fieldWrapper, inputId) => {
    const inputWrap = dom.getElement(`.${INPUT_WRAPPER_CLASS}`, fieldWrapper);
    const errorWrapper = dom.getElement(`.${INPUT_TEXT_ERROR_CLASS}`, fieldWrapper);

    dom.removeClass(inputWrap, INPUT_WRAPPER_ERROR_CLASS);
    dom.addText(errorWrapper, '');

    if (has(this, `state.errors.${formId}.${inputId}`)) delete this.state.errors[formId][inputId];
  };

  hideAllErrorMessage = (form) => {
    const errorHighlightedFields = dom.getCollection(`.${INPUT_WRAPPER_ERROR_CLASS}`, form);
    const errorMessage = dom.getCollection(`.${INPUT_TEXT_ERROR_CLASS}:not(:empty)`, form);

    if (!isEmpty(errorHighlightedFields)) {
      errorHighlightedFields.forEach(
        (field) => dom.removeClass(field, INPUT_WRAPPER_ERROR_CLASS)
      );
    }
    if (!isEmpty(errorMessage)) errorMessage.forEach((message) => dom.addText(message, ''));
  };

  setEmptyFormError = (form) => {
    const { id } = form;
    const inputWraps = dom.getCollection(`.${INPUT_WRAPPER_CLASS}`, form);

    this.state.needReset[id] = true;

    inputWraps.forEach((inputWrap, i) => {
      dom.addClass(inputWrap, INPUT_WRAPPER_ERROR_CLASS);

      if (i === 0) {
        const formRow = inputWrap.closest(`.${INPUT_ROW_CLASS}`);
        const errorWrapper = dom.getElement(`.${INPUT_TEXT_ERROR_CLASS}`, formRow);

        dom.addText(errorWrapper, this.state.errorMessages[id].emptyForm);
      }
    });
  };

  submitForm = (
    url,
    data = null,
    events = {}
  ) => {
    const {
      onStart = () => null,
      onSuccess = () => null,
      onError = () => null,
    } = events;

    // eslint-disable-next-line unicorn/error-message
    if (!url) throw new Error();

    // eslint-disable-next-line no-undef
    const sendSubmit = new XMLHttpRequest();

    sendSubmit.open('POST', url);
    sendSubmit.setRequestHeader('Content-Type', 'application/json');

    sendSubmit.onloadstart = onStart;
    sendSubmit.onload = onSuccess;
    sendSubmit.onerror = onError;
    sendSubmit.send(data);
  };

  resetForm = (form) => {
    this.state.values[form.id] = { ...this.state.defaultValues[form.id] };
    this.state.errors[form.id] = {};
    form.reset();
    this.resetRecaptcha(form.id);
    this.hideAllErrorMessage(form);
  };

  validateHandler = (value = '', inputId, formRowId, formId, onChange = false) => {
    const formSelector = `[id="${formId}"]`;
    const formRowSelector = `[id="${formRowId}"]`;
    const needResetHighlight = get(this, ['state', 'needReset', formId], false);

    if (needResetHighlight) {
      const form = dom.getElement(formSelector);

      this.hideAllErrorMessage(form);
      this.state.needReset[formId] = false;
    }

    const fieldWrapper = dom.getElement(formRowSelector, dom.getElement(formSelector));
    const { split = false } = fieldWrapper?.dataset || {};
    const error = this.validateField(value, formId, inputId);

    if (split) {
      if (error && !onChange) {
        this.showInputsGroupErrorMessages(formId, fieldWrapper, inputId);

        return true;
      }

      this.hideInputsGroupErrorMessage(formId, fieldWrapper, inputId);

      return false;
    }

    if (error && !onChange) {
      this.showErrorMessage(formId, fieldWrapper, inputId);

      return true;
    }

    this.hideErrorMessage(formId, fieldWrapper, inputId);
    this.toggleRecaptchaError({
      formId,
      action: CAPTCHA_ERROR_ACTIONS.HIDE,
    });

    return false;
  };

  // eslint-disable-next-line consistent-return
  onSubmitHandler = (values, form, fieldIds, { url, hash, page }) => {
    // Iterate over values and check if at least one value is true
    const validationResult = this.validateFormData(form, fieldIds);

    const sanitizedValues = values && Object.keys(values)
      .filter((key) => values[key] !== '' && !isNil(values[key]));
    const validCaptcha = this.validateRecaptcha(form.id);
    const formErrors = get(this, ['state', 'errors', form.id]);
    const isFormHasErrors = !isEmpty(formErrors) || validationResult;

    const captchaShowError = (type) => {
      this.showRecaptchaError({
        formId: form.id,
        type,
      });
    };

    this.datepickers.reInitDatepickers(form.id);

    if (isFormHasErrors) {
      if (!validCaptcha) captchaShowError(CAPTCHA_ERROR_TYPES.CLASS_NAME);

      return;
    }

    const isFormHasValue = !isEmpty(sanitizedValues);

    if (!isFormHasValue) {
      this.setEmptyFormError(form);

      if (!validCaptcha) captchaShowError(CAPTCHA_ERROR_TYPES.CLASS_NAME);

      return;
    }

    if (!validCaptcha) {
      captchaShowError(CAPTCHA_ERROR_TYPES.TEXT);

      return;
    }

    const onStart = () => this.showSpinner(form);
    const onSuccess = () => {
      this.showMessage(form, true);
      this.resetForm(form);
    };
    const onError = () => this.showMessage(form, false);
    const data = this.getFormData(form.id, { page, hash });

    try {
      this.submitForm(url, data, {
        onStart,
        onSuccess,
        onError,
      });
    } catch {
      this.showMessage(form, false);
    }
  };

  onChangeHandler = (formId, currentFieldId) => (e) => set(
    this,
    `state.values.${formId}.${currentFieldId}`,
    removeExtraSpaces(e.target.value)
  );

  /**
   * Not used on the project
   */
  onBlurHandler = (formId, currentFieldId, formRowId) => () => {
    const hasEmptyFormErrors = get(this, ['state', 'needReset', formId]);
    const value = get(this, ['state', 'values', formId, currentFieldId], null);
    const isRequired = get(
      this,
      ['state', 'validationRules', formId, currentFieldId, 'validationRules', 'required'],
      false
    );

    if (hasEmptyFormErrors || (isNil(value) && !isRequired)) return;

    this.validateHandler(value || '', currentFieldId, formRowId, formId);
  };

  onChangeValidateHandler = (formId, currentFieldId, formRowId) => () => {
    const value = get(this, ['state', 'values', formId, currentFieldId], '');

    this.validateHandler(
      value,
      currentFieldId,
      formRowId,
      formId,
      true
    );
  };

  createTextField = (fields, form, id, widgetId) => {
    if (isEmpty(fields)) return;

    fields.forEach((field, index) => {
      const formId = form.id;
      const {
        type, checked, id: fieldId, dataset,
      } = field;
      const isRadio = type === 'radio';
      const isSelect = type.includes('select');
      const currentFieldId = widgetId[id].inputIds[!isRadio ? index : 0];

      // for case when Select split in the Row
      if (isSelect) {
        const select = new SelectWidget(field, SELECT_CONFIG);

        dom.on(field, 'change', this.onChangeHandler(formId, currentFieldId));
        dom.on(field, 'change', this.onChangeValidateHandler(formId, currentFieldId, id));
        dom.on(form, 'reset', () => {
          select.destroy();
          select.init();
        });

        return;
      }

      this.datepickers.setDatepickerById({
        fieldId, formId, currentFieldId, dataset, fieldRowId: id,
      });

      dom.on(field, 'input', this.onChangeHandler(formId, currentFieldId));
      dom.on(field, 'input', this.onChangeValidateHandler(formId, currentFieldId, id));

      if (!isRadio || !checked) return;

      const { value } = field;
      const withoutExtraSpaces = removeExtraSpaces(value);

      set(
        this,
        `state.values.${formId}.${currentFieldId}`,
        withoutExtraSpaces
      );
      set(
        this,
        `state.defaultValues.${formId}.${currentFieldId}`,
        withoutExtraSpaces
      );
    });
  };

  createSelectField = (fields, form, id, widgetId) => {
    if (isEmpty(fields)) return;

    const formId = form.id;

    fields.forEach((field, index) => {
      const currentFieldId = widgetId[id].inputIds[index];
      const select = new SelectWidget(field, SELECT_CONFIG);

      dom.on(field, 'change', this.onChangeHandler(formId, currentFieldId));
      dom.on(field, 'change', this.onChangeValidateHandler(formId, currentFieldId, id));

      dom.on(form, 'reset', () => {
        select.destroy();
        select.init();
      });
    });
  };

  onloadRecaptchaCallback = () => isArray(this.forms)
    && this.forms.forEach((form) => this.initRecaptcha(form));

  initRecaptcha = (form) => {
    if (!window.grecaptcha || !form) return;

    const siteKey = form.dataset.siteKeyRecaptcha;

    const [formCaptcha] = dom.getCollection('.g-recaptcha', form);

    if (!formCaptcha) return;

    const captchaId = `captcha-${form.id}`;

    formCaptcha.setAttribute('id', captchaId);

    const widgetId = window.grecaptcha.render(captchaId, {
      sitekey: siteKey,
      callback: (token) => this.handleRecaptchaCallback(form.id, token),
    });

    this.setRecaptchaState(form.id, {
      captchaId,
      widgetId,
      token: null,
      rendered: true,
    });
  };

  resetRecaptcha = (formId) => {
    if (!window.grecaptcha || !formId) return;

    const { widgetId } = this.getRecaptchaState(formId);

    if (isNil(widgetId)) return;

    window.grecaptcha.reset(widgetId);

    this.setRecaptchaState(formId, {
      token: null,
    });
  };

  getRecaptchaState = (formId) => get(this.state, ['recaptcha', formId], {});

  getCaptchaElement = (formId) => {
    const { captchaId } = this.getRecaptchaState(formId);

    return dom.getElement(`#${captchaId}`);
  };

  setRecaptchaState = (formId, data = {}) => {
    const recaptchaState = this.getRecaptchaState(formId);

    this.state.recaptcha = {
      ...(isPlainObject(this.state.recaptcha) && this.state.recaptcha),
      [formId]: {
        ...recaptchaState,
        ...data,
      },
    };
  };

  handleRecaptchaCallback = async (formId, token) => {
    this.setRecaptchaState(formId, {
      token: null,
    });

    if (!token) {
      this.showRecaptchaError({
        formId,
      });

      return;
    }

    const href = `${this.embedApiService}/recaptcha/${this.projectId}/verify`;
    const body = {
      response: token,
    };

    try {
      const response = await fetch(href, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        const json = await response.json();

        if (json.success) {
          this.hideRecaptchaError({
            formId,
          });
        } else {
          this.showRecaptchaError({
            formId,
          });
        }
      }
    } catch {
      this.showRecaptchaError({
        formId,
      });
    }

    this.setRecaptchaState(formId, {
      token,
    });
  };

  validateRecaptcha = (formId) => {
    const { rendered, token } = this.getRecaptchaState(formId);

    return !((rendered && isNil(token)));
  };

  showRecaptchaError = (data = {}) => {
    this.toggleRecaptchaError({ ...data, action: CAPTCHA_ERROR_ACTIONS.SHOW });
  };

  hideRecaptchaError = (data = {}) => {
    this.toggleRecaptchaError({ ...data, action: CAPTCHA_ERROR_ACTIONS.HIDE });
  };

  toggleRecaptchaError = ({ formId, type, action } = {}) => {
    if (type === CAPTCHA_ERROR_TYPES.CLASS_NAME) {
      this.toggleCaptchaElementErrorClass(formId, action);

      return;
    }
    if (action === CAPTCHA_ERROR_TYPES.TEXT) {
      this.toggleCaptchaElementErrorText(formId, action);

      return;
    }

    this.toggleCaptchaElementErrorClass(formId, action);
    this.toggleCaptchaElementErrorText(formId, action);
  };

  toggleCaptchaElementErrorClass = (formId, action) => {
    const elCaptcha = this.getCaptchaElement(formId);

    if (!elCaptcha) return;
    if (action === CAPTCHA_ERROR_ACTIONS.SHOW) {
      dom.addClass(elCaptcha, CAPTCHA_ERROR_CLASS);

      return;
    }

    dom.removeClass(elCaptcha, CAPTCHA_ERROR_CLASS);
  };

  toggleCaptchaElementErrorText = (formId, action) => {
    const elCaptcha = this.getCaptchaElement(formId);

    if (!elCaptcha) return;

    const elInputError = elCaptcha.nextElementSibling;

    if (!elInputError) return;

    const errorMessages = get(this.state, ['errorMessages', formId], {});
    const errorText = action === CAPTCHA_ERROR_ACTIONS.SHOW ? errorMessages.captchaError : '';

    dom.addText(elInputError, errorText);
  };
}

export default Form;
