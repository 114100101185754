export const getSVGPromise = async (path) => {
  const response = await fetch(path);
  const svg = await response.text();

  return new DOMParser()
    .parseFromString(svg, 'text/xml')
    .querySelector('svg');
};

export const getSizeSVGFromViewBox = async (nodeImage) => {
  if (nodeImage?.src && nodeImage.src.includes('.svg')) {
    const svgElement = await getSVGPromise(nodeImage.src);
    const { width, height } = svgElement.viewBox.baseVal;

    return { width, height };
  }

  return {};
};

export default {
  getSVGPromise,
  getSizeSVGFromViewBox,
};
