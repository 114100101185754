export const LAYOUT_FEED = 'feed';
export const LAYOUT_GRID = 'grid';
export const LAYOUT_LIST = 'list';
export const LAYOUT_MASONRY = 'masonry';
export const LAYOUTS = [LAYOUT_LIST, LAYOUT_FEED, LAYOUT_GRID, LAYOUT_MASONRY];
export const PAGINATION_TYPE = {
  numeric: 'numeric',
  prevNext: 'prev-next',
  dots: 'dots',
  infinite: 'infinite',
};
export const DEFAULT_SPACING = 25;
