const IMAGE_SELECTOR = 'booking__img';
const IMAGE_ERROR = 'no-image';
const BUTTON_SELECTOR = 'booking__btn';

const PORTRAIT_0667 = 2 / 3;
const PORTRAIT_075 = 3 / 4;
const SQUARE_1 = 1;
const LANDSCAPE_1333 = 4 / 3;
const LANDSCAPE_15 = 3 / 2;

const ASPECT_RATIO = {
  PORTRAIT_0667,
  PORTRAIT_075,
  SQUARE_1,
  LANDSCAPE_1333,
  LANDSCAPE_15,
};

const BUTTON_PADDING_VERTICAL = 12;
const BUTTON_LINE_HEIGHT = 2.4;

export {
  ASPECT_RATIO,
  IMAGE_ERROR,
  IMAGE_SELECTOR,
  BUTTON_SELECTOR,
  BUTTON_PADDING_VERTICAL,
  BUTTON_LINE_HEIGHT,
};
