import dom from '../../wrapper/DomWrapper';

import getImageUrl from './getImageUrl';

const IMAGE_TAGS = [
  'og:image',
  'og:image:width',
  'og:image:height',
  'twitter:image',
];
const IMAGE_SIZE = {
  width: 1200,
  height: 630,
};

/**
 *
 * @param {Object=} image
 * @param {boolean=} isStaticImagesPublished
 * @param {boolean=} isOriginalImage
 */
const updateImage = ({ image, isStaticImagesPublished, isOriginalImage }) => {
  const imageUrl = getImageUrl({ image, isStaticImagesPublished, isOriginalImage });

  if (!imageUrl) return;

  const elHead = dom.document.head;

  IMAGE_TAGS.forEach((name) => {
    const isWidth = name.includes('width');
    const isHeight = name.includes('height');
    const sizeValue = isWidth ? IMAGE_SIZE.width : IMAGE_SIZE.height;
    const content = isWidth || isHeight ? sizeValue : imageUrl;

    if (!content) return;

    const elMeta = dom.getElement(`meta[property="${name}"]`);

    if (elMeta) {
      elMeta.setAttribute('content', content);
    } else {
      elHead.insertAdjacentHTML('beforeend', `<meta property="${name}" content="${content}"></meta>`);
    }
  });
};

export default updateImage;
