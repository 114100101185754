import getStateValue from '../../helpers/getStateValue';
import dom from '../../wrapper/DomWrapper';

export default () => {
  const isBackupMode = getStateValue('isBackup', false);

  if (!isBackupMode) return;

  const headerSection = dom.getElement('.header');
  const siteSection = dom.getElement('.layout.main');
  const footerSection = dom.getElement('.footer');
  const siteSectionLinks = siteSection ? dom.getCollection('a', siteSection) : [];
  const footerSectionLinks = footerSection ? dom.getCollection('a', footerSection) : [];
  const basketLink = dom.getElement('.basket', headerSection);

  const allInnerLinksExceptMenu = [
    ...(siteSectionLinks || []),
    ...(footerSectionLinks || []),
  ];

  if (basketLink) dom.updateStyle(basketLink, { pointerEvents: 'none' });

  allInnerLinksExceptMenu.forEach((link) => {
    dom.updateStyle(link, {
      pointerEvents: 'none',
    });
  });
};
