import dom from '../../../wrapper/DomWrapper';

const SELECTOR = '.slider__section:not(.slider__section-component) > .slick-slider > .glide__track > .glide__slides > .glide__slide--active';

/**
 *
 * @param {HTMLElement} elLayout
 * @returns {HTMLElement}
 */
const getBgActiveElSlide = (elLayout) => dom.getElement(SELECTOR, elLayout);

export default getBgActiveElSlide;
