import dom from '../../../wrapper/DomWrapper';

import errorHandler from './errorHandler';
import pictureErrorHandler from './pictureErrorHandler';

const galleryErrorHandler = () => {
  const selectorName = '.gallery-wrap ._error-wrap';
  const onError = (elImage, elParent) => {
    pictureErrorHandler(elImage, elParent, () => {
      dom.addClass(elParent, 'picture-wrap_unavailable');
      dom.addClass(elParent, 'picture-wrap_unavailable_hide');
    });
  };

  errorHandler(selectorName, onError);
};

export default galleryErrorHandler;
