const isString = require('lodash/isString');

/**
 * Util for get file extension from file name
 * @param {string} filename
 * @return {string} fileExtension
 */

const getFileExtension = (filename) => {
  if (!isString(filename)) return '';

  const parts = filename.split('.');

  return (parts.length > 1) ? parts.pop() : '';
};

module.exports = getFileExtension;
