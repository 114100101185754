import get from 'lodash/get';

const getBlogMenuItem = (menu) => {
  const linkedList = get(menu, 'list', []);
  const unlinkedList = get(menu, 'unlinked', []);

  return [...unlinkedList, ...linkedList].find((link) => link.blog);
};

export default getBlogMenuItem;
