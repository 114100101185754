import {
  PRODUCTS_ORDER_MAP_ECWID,
} from '../../../../../../../components/Ecommerce/Ecwid/Custom/constants';

// ecomdash api not correct sort products with variants (parent products)
// this helper provide correct sort by price

const { price_asc: PRICE_ASC, price_desc: PRICE_DESC } = PRODUCTS_ORDER_MAP_ECWID;
const PRICE_SORT = new Set([PRICE_ASC, PRICE_DESC]);

/**
 *
 * @param {string=} sortBy
 * @param {array} products
 * @returns {array}
 */
const sortByPrice = (sortBy, products) => products.sort((a, b) => {
  if (sortBy === PRICE_ASC) return a.price - b.price;

  return b.price - a.price;
});

/**
 *
 * @param {string=} sortBy
 * @param {array} products
 * @returns {array}
 */
const sortProducts = (sortBy, products) => {
  if (products?.length === 0) return [];

  const isPriceSort = PRICE_SORT.has(sortBy);

  if (isPriceSort) return sortByPrice(sortBy, products);

  return products;
};

export default sortProducts;
