import isEmpty from 'lodash/isEmpty';

import { CURRENT_PAGE_ANCHOR_CLASS_NAME } from '../../../constants';
import animateScroll from '../../../helpers/animateScroll';
import dom from '../../../wrapper/DomWrapper';
import triggerLoadLazyImages from '../../LazyLoad/utils/triggerLoadLazyImages';

// eslint-disable-next-line import/no-cycle
import {
  getHeaderOffset,
  getSectionId,
  getTopStickyOffset,
  setUrlHash,
} from './index';

const { getCleanUrl } = require('../../../../helper/urls/convertSrcToCorrect');

export default (anchors) => {
  if (isEmpty(anchors)) return;

  anchors.forEach(async (anchor) => {
    const { hash } = anchor;
    const sectionId = getSectionId(hash);
    const cleanHash = getCleanUrl(hash);
    const cleanSectionId = getSectionId(cleanHash);
    const elSection = dom.getElement(cleanSectionId);

    if (!elSection) return;

    anchor.setAttribute('href', sectionId);
    // eslint-disable-next-line unicorn/consistent-destructuring
    anchor.classList.add(CURRENT_PAGE_ANCHOR_CLASS_NAME);

    dom.on(anchor, 'click', async (e) => {
      e.preventDefault();

      await triggerLoadLazyImages();

      const endPosition = elSection.offsetTop;
      const headerOffset = getHeaderOffset();
      const topStickyOffset = getTopStickyOffset();
      const position = endPosition - headerOffset - topStickyOffset;

      animateScroll(position);
      setUrlHash(sectionId);
    });
  });
};
