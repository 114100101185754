import wrapFontName from '../../helper/utils/wrapFontName';

const cssVarList = ['--font_primary', '--font_secondary', '--font_complementary', '--font_primaryBackup'];

const normilizeCSSFonts = () => {
  const root = document.documentElement;

  cssVarList.forEach((variableCSS) => {
    const value = getComputedStyle(root).getPropertyValue(variableCSS);
    const wrap = wrapFontName(value);

    root.style.setProperty(variableCSS, wrap);
  });
};

export default normilizeCSSFonts;
