import isNull from 'lodash/isNull';

import { DEVICES, getDeviceSizes, QUAD } from '../../device';
import dom from '../wrapper/DomWrapper';

import { retinaMediaQuery } from './constants';
import getStateValue from './getStateValue';

const { navigator: { platform, userAgent, vendor } } = dom.window;

export const findDevice = (width, sizes = {}) => {
  for (const device in sizes) {
    if (Object.prototype.hasOwnProperty.call(sizes, device)) {
      const [min, max] = sizes[device];

      if (width >= min && (width <= max || isNull(max))) return device;
    }
  }

  return QUAD;
};

/**
 * Function for get device type.
 * @param {number} width
 * @param {boolean} note
 * @param {boolean} fix
 * @return {string} type of device
 */
export const getDeviceType = (
  width = dom.window.innerWidth,
  note = false,
  fix = true
) => {
  const sizes = getDeviceSizes(note, fix);

  return findDevice(width, sizes);
};

export default {
  isChrome: () => /Chrome/.test(userAgent) && /Google Inc/.test(vendor),
  isSafari: () => /safari/i.test(userAgent) && !/chrome/i.test(userAgent),
  isIe: () => userAgent.includes('Trident'),
  isEdge: () => userAgent.includes('Edge'),
  isMozilla: () => typeof InstallTrigger !== 'undefined',
  isMac: () => platform.match(/(mac|iphone|ipod|ipad)/i),
  isIos: () => platform.match(/(iphone|ipod|ipad)/i),
  isRetina: () => dom.window.devicePixelRatio > 1
    || dom.window.matchMedia(retinaMediaQuery).matches,
  isMobile: () => /mobile/i.test(userAgent) || userAgent.match(/android|webos|blackberry/i),
  isMobileDevice: () => [DEVICES.PHONE, DEVICES.TABLET].includes(getDeviceType()),
  isPhoneDevice: () => DEVICES.PHONE === getDeviceType(),
  isInFrame: dom.window.parent.self !== dom.window.self, // true - in iframe
};

// eslint-disable-next-line no-underscore-dangle
dom.window.__VERSION__ = () => `Application Version: ${getStateValue('publishAssetsVersion')}`;
