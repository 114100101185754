import CustomRouter from './custom/router';
import NativeRouter from './native/router';

import { ECWID_CUSTOM_PAGE_TYPE, ECWID_NATIVE_PAGE_TYPE } from '../constants';

const ROUTER_BY_TYPE = new Map([
  [ECWID_CUSTOM_PAGE_TYPE, CustomRouter],
  [ECWID_NATIVE_PAGE_TYPE, NativeRouter],
]);

function connectRouter(storeType, ...args) {
  const Router = !ROUTER_BY_TYPE.has(storeType)
    ? ROUTER_BY_TYPE.get(ECWID_CUSTOM_PAGE_TYPE)
    : ROUTER_BY_TYPE.get(storeType);

  return new Router(...args);
}

export {
  CustomRouter,
  NativeRouter,
  connectRouter,
};
