import escape from 'lodash/escape';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';

import getTranslation from '../../../../../../i18n';
import dom from '../../../../../wrapper/DomWrapper';
import {
  PRODUCT_OPTION_TYPES,
  PRODUCT_OPTIONS_GROUP_TEMPLATE,
} from '../../custom/Product/constants';

import { CLASS_NAMES } from './constants';

const i18next = getTranslation();

export const PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE = (
  name,
  value,
  label = '',
  disabled = false
) => (`
<div class="radio-wrap ${disabled ? CLASS_NAMES.showTooltip : ''}">
    <input
        type="radio"
        id="${name}-${label}-${value}"
        class="radio-btn"
        name="${name}"
        value="${value}"
        ${disabled ? 'disabled' : ''}
    >
    <label for="${name}-${label}-${value}" class="radio-label">
    <span class="radio-label-text">
        <span class="choice-title">
            ${label}
        </span>
    </span>
    <div class="tooltip__wrap">
        <span class="tooltip">${i18next['sh.wf.ecom_product_page.tooltip-text-out-of-stock']}</span>
    </div>
    </label>
</div>
`);

class ProductOptions {
  constructor(
    name,
    choice,
    currencyFormatter = (price) => price
  ) {
    this.name = escape(name);
    this.choice = this.formatChoice(choice);
    this.currencyFormatter = currencyFormatter;
    this.html = '';
    this.parent = null;

    this.createRelatedList();
  }

  formatChoice = (choice) => {
    if (!isArray(choice)) return choice;

    return choice.map((data) => ({
      ...data,
      value: data.text,
      text: escape(data.text),
    }));
  };

  createRelatedList = () => {
    const options = this.choice.reduce((group, {
      disabled,
      text,
    }) =>
    // eslint-disable-next-line unicorn/prefer-spread,implicit-arrow-linebreak
      group.concat(
        PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE(
          this.name,
          text,
          text,
          disabled
        )
      ),
    '');

    this.html = PRODUCT_OPTIONS_GROUP_TEMPLATE(
      PRODUCT_OPTION_TYPES.RADIO,
      this.name,
      options
    );
  };

  connect = (element, onChange) => this.connectRelatedList(element, onChange);

  connectRelatedList = (
    element,
    onChange = () => null
  ) => {
    this.parent = dom.createElement('div');

    dom.addHtml(this.parent, this.html);
    element.append(this.parent);

    const inputs = this.getInputsByProperty({
      selector: '.radio-wrap>input',
      property: 'name',
    });

    inputs.forEach((input) => {
      dom.on(input, 'click', onChange);
    });
  };

  getInputsByProperty({
    selector = 'input',
    property = 'name',
    parent = this.parent || dom.document,
  }) {
    const inputs = dom.getCollection(selector, parent);

    if (isNil(inputs)) return [];

    return [...inputs].filter((input) => escape(input[property]) === this.name);
  }

  clear = () => {
    const inputs = this.getInputsByProperty({
      selector: '.radio-wrap>input',
      property: 'name',
    });

    for (const input of inputs) {
      input.disabled = false;
      input.closest('.radio-wrap')?.classList?.remove(CLASS_NAMES.showTooltip);
    }
  };

  unCheck = () => {
    const inputs = this.getInputsByProperty({
      selector: '.radio-wrap>input',
      property: 'name',
    });

    for (const input of inputs) {
      input.checked = false;
    }
  };
}

export default ProductOptions;
