import { IMAGE_NOT_FIXED_SIZE_CLASS_NAME, IMAGE_SVG_CLASS_NAME } from '../../../../components/Photo/constants';
import dom from '../../../wrapper/DomWrapper';
import writeSVG from '../../SVGLoader/writeSVG';

const cb = (elWrapper) => async (elSvg) => {
  const width = elSvg?.getAttribute('width');
  const height = elSvg?.getAttribute('height');
  const isFixedSize = !!width && !!height;

  if (!isFixedSize) dom.addClass(elWrapper, IMAGE_NOT_FIXED_SIZE_CLASS_NAME);
};

const fixSvgImages = () => {
  const collections = dom.getCollection(`.${IMAGE_SVG_CLASS_NAME}`);

  if (!collections) return;

  const arr = [...collections];

  for (const elWrapper of arr) {
    const elPicture = dom.getElement('picture', elWrapper);
    const { iesrc: src } = elPicture?.dataset || {};

    if (!src) continue;

    writeSVG(src, cb(elWrapper));
  }
};

export default fixSvgImages;
