/**
 *
 * @param {number} defaultProductId
 * @param {boolean} productWithOptions
 * @param {{options: array}} product
 * @returns {T|*}
 */
const getDefaultProduct = ({
  defaultProductId,
  productWithOptions,
  product,
}) => {
  const allOptions = product?.options || [];
  const allProducts = allOptions[0]?.products || [];

  if (productWithOptions) return allProducts.find((p) => p.id === defaultProductId);

  return product;
};

export default getDefaultProduct;
