/* eslint-disable camelcase */

import { LAYOUT_GRID } from '../../../../../ItemsView/constants';

export const NATIVE_LAYOUT_SETTINGS_DEFAULT = {
  spacing: 16,
  layoutType: LAYOUT_GRID,
};

export const NATIVE_DEFAULT_STOREFRONT = {
  product_list_image_aspect_ratio: 'SQUARE_1',
};

export const NATIVE_EFFECT_CLASS_NAME = 'with-native-effects';
