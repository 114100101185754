import { VIDEO_PROVIDER_NAMES } from '../../../../constants';
import { VIMEO_DEFAULT_CONFIG, YOUTUBE_DEFAULT_CONFIG } from '../Providers/constants';

export default (provider, defaultConfig, updateConfig) => {
  switch (provider) {
    case VIDEO_PROVIDER_NAMES.YOUTUBE:
      return {
        ...YOUTUBE_DEFAULT_CONFIG,
        ...defaultConfig,
        ...updateConfig,
      };
    case VIDEO_PROVIDER_NAMES.VIMEO:
      return {
        ...VIMEO_DEFAULT_CONFIG,
        ...defaultConfig,
        ...updateConfig,
      };
    default:
      return null;
  }
};
