import checkImageV2 from './checkImageV2';
import checkIsSvg from './checkIsSvg';
import checkProjectWithImageV2 from './checkProjectWithImageV2';

const checkImageResizable = require('../../../helpers/checkImageResizable');

/**
 * Util for getting image options
 * @param {Object} dynamic
 * @param {Object} options
 * @param {boolean=} options.isTemplate
 * @param {Object=} options.projectOptions
 * @returns {{
 * isImageV2: (Boolean),
 * isImageResizable: (boolean),
 * canResizeImage: (boolean)
 * }}
 */
export default function getImageOptions(dynamic, {
  isTemplate,
  projectOptions,
} = {}) {
  const {
    src,
    type,
    transform,
    contentType,
  } = dynamic || {};
  const projectWithImageV2 = checkProjectWithImageV2(projectOptions);
  const isImageV2 = isTemplate || projectWithImageV2 || checkImageV2(type, transform);
  const isImageResizable = checkImageResizable(src, contentType);
  const isSvg = checkIsSvg(src, contentType);

  return {
    isImageV2, // image with new structure
    isImageResizable, // image file extension support IMAGE_RESIZE_API_SERVICE
    canResizeImage: projectWithImageV2 && isImageResizable, // image can be resized with IMAGE_RESIZE_API_SERVICE
    isSvg,
  };
}
