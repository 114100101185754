import dom from '../wrapper/DomWrapper';

/**
 *
 * @param {string} cssVar
 * @returns {number|number}
 */
const parseRootFloat = (cssVar) => {
  const varStr = dom.getCssVar(cssVar);
  const varFloat = Number.parseFloat(varStr);

  return Number.isNaN(varFloat) ? 0 : varFloat;
};

export default parseRootFloat;
