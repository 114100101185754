import checkProjectWithAnimation from '../../../../../../helpers/checkProjectWithAnimation';
import getStateValue from '../../../../../../helpers/getStateValue';
import { addInViewWait, removeInViewWait } from '../../../../../../helpers/inView';
import dom from '../../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../../wrapper/WidgetWrapper';
import {
  ECWID_WIDGET_STOREFRONT_TYPE,
  PAGE_CONTAINER_ID,
  RETRY_BUTTON_ID,
  RETRY_CONTAINER_ID,
  SPINNER_CONTAINER_ID,
} from '../../constants';
import { addGalleryListener, connectEcwidScript, initEcwidStorefront } from '../../utils';

class CatalogPage extends WidgetWrapper {
  init() {
    this.elPage = dom.getElement(`#${PAGE_CONTAINER_ID}`);
    this.elSpinner = dom.getElement(`#${SPINNER_CONTAINER_ID}`);
    this.elRetry = dom.getElement(`#${RETRY_CONTAINER_ID}`);
    this.elRetryButton = dom.getElement(`#${RETRY_BUTTON_ID}`);
    this.storeId = getStateValue(['ecommerce', 'storeId']);
    this.withAnimation = checkProjectWithAnimation();

    this.showSpinner();
    this.initAnimation();

    if (dom.window?.Ecwid) dom.window.Ecwid.OnPageLoaded.add(() => addGalleryListener());

    return initEcwidStorefront(this.selector, ECWID_WIDGET_STOREFRONT_TYPE)
      .then(() => {
        dom.off(this.elRetryButton, 'click', this.retryButtonHandler);
      })
      .catch(() => {
        this.showRetry();
        dom.on(this.elRetryButton, 'click', this.retryButtonHandler.bind(this));
      })
      .finally(() => {
        this.hideSpinner();
        this.updateAnimation();
      });
  }

  initAnimation = () => {
    if (!this.withAnimation) return;

    addInViewWait(this.elPage);
  };

  updateAnimation = () => {
    if (!this.withAnimation) return;

    removeInViewWait(this.elPage);
  };

  reconnect() {
    connectEcwidScript(
      this.storeId,
      {
        onLoad: () => { this.hideRetry(); this.init(); },
      }
    );

    if (dom.getElement('#store-script')) dom.removeElement(dom.getElement('#store-script'));
  }

  retryButtonHandler() {
    if (!dom.window.Ecwid) {
      this.reconnect();
    } else {
      this.hideRetry();
      this.init();
    }
  }

  showRetry() {
    dom.show(this.elRetry);
  }

  hideRetry() {
    dom.hide(this.elRetry);
  }

  showSpinner() {
    dom.show(this.elSpinner);
  }

  hideSpinner() {
    dom.hide(this.elSpinner);
  }
}

export default CatalogPage;
