import flow from 'lodash/flow';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

import { getDeviceType } from '../../helpers/browser';
import dom from '../../wrapper/DomWrapper';

import { getLimit, toDom } from './utils';

class Pagination {
  static events = {
    onInit: 'init',
    onChange: 'change',
  };

  lastMeta = null;

  // DOMElement - current pager
  pager = null;

  state = { isLoading: false };

  handlers = {
    // eslint-disable-next-line no-use-before-define
    [Pagination.events.onChange]: [],
    // eslint-disable-next-line no-use-before-define
    [Pagination.events.onInit]: [],
  };

  constructor(options, container, renderer) {
    this.options = options;
    this.renderer = renderer;
    this.container = container;

    const limit = getLimit(this.options.itemSize, this.options, getDeviceType());

    this.setState('limit', limit);
  }

  getTemplateData = (data) => data;

  attachListeners = (DOM) => DOM;

  render = (meta = this.lastMeta) => {
    if (!meta) return;

    this.lastMeta = meta;

    const { selector } = this.options;
    const render = flow(
      this.getTemplateData,
      this.renderer.render,
      toDom,
      this.attachListeners
    );
    const next = render(meta);
    const current = dom.getElement(selector);

    if (!current) {
      const elPaginationWrapper = dom.getElement('.pagination-wrapper', this.container);

      if (elPaginationWrapper) {
        elPaginationWrapper.append(next);
      } else {
        this.container.append(next);
      }
    } else {
      current.parentNode.replaceChild(next, current);
    }

    this.onDidMount(next);
    this.pager = next;
  };

  setPage = (pageNum) => {
    const { limit } = this.state;
    const offset = pageNum * limit;

    this.setState('isLoading', true);

    if (this.pager) { this.pager.style.pointerEvents = 'none'; }

    this.fireEvent(Pagination.events.onChange, { limit, offset });
  };

  on = (event, handler) => {
    const events = isArray(event) ? event : [event];

    // eslint-disable-next-line no-underscore-dangle
    events.forEach((_event) => this.addHandler(_event, handler));
  };

  addHandler = (event, handler) => {
    if (!this.handlers[event] || this.handlers[event].includes(handler)) return;

    this.handlers[event].push(handler);
  };

  fireEvent = (event, data) => {
    if (!this.handlers[event] || this.handlers[event].length === 0) return;

    this.handlers[event].forEach((handler) => {
      if (!isFunction(handler)) return;

      handler(data, event);
    });
  };

  // eslint-disable-next-line no-return-assign
  setState = (key, value) => this.state[key] = value;

  onDidMount = () => {};

  remove = () => {
    try {
      this.pager.remove();
      // eslint-disable-next-line no-empty
    } catch {}
  };
}

export default Pagination;
