import { BOOKING_CATALOG_PAGE_WRAPPER_ID } from '../../booking/router/constants';
import ItemsView from '../ItemsView/ItemsView';

class CatalogPage {
  init = () => {
    (new ItemsView(`#${BOOKING_CATALOG_PAGE_WRAPPER_ID} [data-widget="itemsView"]`)).init();
  };
}

export default CatalogPage;
