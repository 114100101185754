const get = require('lodash/get');
const isArray = require('lodash/isArray');

const getContentImageTransform = require('./getContentImageTransform');
const getFullImageTransform = require('./getFullImageTransform');
const getPreviewImageTransform = require('./getPreviewImageTransform');
const { IMAGE_ASPECT_RATIO } = require('./constants');
const checkImageResizable = require('../checkImageResizable');

/**
 *
 * @param {Object} layoutSettings
 * @param {array} images
 * @returns {array}
 */
// eslint-disable-next-line default-param-last
const getBlogImagesResize = (layoutSettings = {}, images) => {
  if (!isArray(images)) return [];

  const layout = get(layoutSettings, 'layout');
  const isGrid = layout === 'grid';
  const isList = layout === 'list';
  const isFeed = layout === 'feed';

  const layoutStyle = get(layoutSettings, `layoutSettings.${layout}.style`);
  const layoutRowLimit = get(layoutSettings, `layoutSettings.${layout}.rowLimit`);
  const layoutWidth = get(layoutSettings, `layoutSettings.${layout}.width`);
  const layoutAspect = get(layoutSettings, `layoutSettings.${layout}.aspect`);
  const layoutAspectRatio = IMAGE_ASPECT_RATIO[layoutAspect] || 1;

  const postPageFormat = get(layoutSettings, 'postPageSettings.postPageFormat');
  const postPageWidth = get(layoutSettings, `postPageSettings.${postPageFormat}.postPageWidth`);

  return images.reduce((acc, image) => {
    const {
      url,
      original,
      isSeo,
      isPreview,
      isCustomPreviewImage,
      widthSetting,
      showCoverPhoto,
      contentType,
    } = image || {};
    const isImageResizable = checkImageResizable(url, contentType);

    if (!isImageResizable) return acc;
    if (isSeo) return [...acc, image];

    const isHeroPostFormat = postPageFormat === 'hero' || !!showCoverPhoto;

    const withCustomPreviewImage = isPreview && isCustomPreviewImage;
    const withContentPreviewImage = isPreview && !isCustomPreviewImage;
    const withPreviewImage = withCustomPreviewImage || withContentPreviewImage;

    const withPreviewTransform = withPreviewImage && (isGrid || isList);
    const withContentTransform = (withContentPreviewImage || !isPreview) && widthSetting;
    const withHeroTransform = withPreviewImage && isHeroPostFormat;

    const transforms = {
      ...withPreviewTransform && {
        previewTransform: getPreviewImageTransform({
          layout,
          layoutStyle,
          layoutRowLimit,
          layoutWidth,
          layoutAspectRatio,
          original,
        }),
      },
      ...(withContentTransform && isFeed) && {
        previewTransform: getContentImageTransform({
          original,
          widthSetting,
          pageWidth: layoutWidth,
        }),
      },
      ...withContentTransform && {
        transform: getContentImageTransform({
          original,
          widthSetting,
          pageWidth: postPageWidth,
        }),
      },
      ...withHeroTransform && { heroTransform: getFullImageTransform({ original }) },
    };

    return [
      ...acc,
      {
        ...image,
        ...transforms,
      },
    ];
  }, []);
};

module.exports = getBlogImagesResize;
