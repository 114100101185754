/**
 * Helper that return remaining time for Countdown
 * @param {number} distance
 * @returns {{hours: number, seconds: number, minutes: number, days: number}}
 */
const getRemainingTime = (distance) => ({
  days: Math.floor(distance / (1000 * 60 * 60 * 24)),
  hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
  minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
  seconds: Math.floor((distance % (1000 * 60)) / 1000),
});

export default getRemainingTime;
