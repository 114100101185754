import entries from 'lodash/entries';

import {
  generatePictureResizeSourceList,
  getPictureSourceMedia,
} from '../../../../helper/image';

/**
 *
 * @param {string} src
 * @param {Object} image
 * @param {Object} sizes
 * @param {{ apiUrls: Object, staticData: Object, originalSrc: string, isValidImage: boolean }} options
 * @returns {string}
 */
const getPictureSource = (src, image, sizes, options) => {
  if (!options.isValidImage) return `<source srcset="${options.originalSrc}"  />`;

  const {
    hash,
    transform,
    isPreview,
    isHero,
  } = image;
  let subDir = null;

  if (isHero) {
    subDir = '/hero';
  } else if (isPreview) {
    subDir = '/preview';
  }

  const list = generatePictureResizeSourceList(src, sizes, {
    hash,
    subDir,
    transform,
  }, options);

  return entries(list)
    .reduce((acc, [device, { srcSet, media }]) => `${acc}<source data-device="${device}" media="${getPictureSourceMedia(media)}" srcset="${srcSet}"  />`, '');
};

export default getPictureSource;
