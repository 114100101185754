/**
 * Utils for cut number after dots without floor
 * @param {number} number
 * @param {number=} digAfterDot
 * @return {number}
 */
const cutNumber = (number, digAfterDot = 3) => {
  const divNum = 10 ** digAfterDot;

  return parseInt(`${number * divNum}`, 10) / divNum;
};

module.exports = cutNumber;

