/**
 *
 * @param {number} int
 * @param {HTMLCollection|null} collection
 */
const updateNumberHtml = (int, collection) => {
  if (!collection) return;

  const str = int < 10 ? `0${int}` : Number(int).toString();
  const [elFirst, elSecond] = [...collection];

  if (!elSecond) {
    elFirst.innerHTML = str;

    return;
  }

  const [charFirst, charSecond] = str;

  elFirst.innerHTML = charFirst;
  elSecond.innerHTML = charSecond;
};

export default updateNumberHtml;
