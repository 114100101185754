import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import {
  DEFAULT_MAP_CENTER_COORDINATES, DEFAULT_MAP_ZOOM, MARKER_ICONS, MAX_MAP_ZOOM,
} from './constants';
import { checkIfAddressIsURL, getCoordinatesForAddress, getCoordinatesForURL } from './utils';

class GoogleMap extends WidgetWrapper {
  constructor(selector) {
    super(selector);

    this.state = {
      mapSettings: {},
      firstMarkerPosition: {},
    };
  }

  init = () => {
    const mapWidgets = dom.getCollection(this.selector);

    if (isNil(mapWidgets)) return;

    mapWidgets.forEach((widget) => {
      const mapSettings = get(widget, 'dataset.settings', null);

      if (isNil(mapSettings)) return;

      const settings = JSON.parse(mapSettings);

      this.state.mapSettings = settings;

      this.drawMap(widget, settings);
    });
  };

  drawMap = async (widget, mapSettings) => {
    const { google } = window;
    const {
      mapTypeId, mapZoom, markers, zoomControl, draggableMap, clickableIcons, mapTypeControl,
    } = mapSettings;

    if (!google || (google && !google.maps)) return;

    const mapPref = {
      zoom: mapZoom,
      mapTypeId: mapTypeId.toLowerCase(),
      zoomControl,
      minZoom: 4,
      maxZoom: 21,
      scaleControl: false,
      rotateControl: false,
      mapTypeControl,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'terrain', 'hybrid'],
      },
      streetViewControl: false,
      disableDoubleClickZoom: false,
      fullscreenControl: false,
      center: DEFAULT_MAP_CENTER_COORDINATES,
      // should be 'cooperative' for ctrl + scroll wheel zooming
      gestureHandling: draggableMap ? 'cooperative' : 'none',
      clickableIcons,
    };

    const mapInstance = new google.maps.Map(widget, mapPref);
    const bounds = new google.maps.LatLngBounds();

    this.state.firstMarkerPosition = await getCoordinatesForAddress(markers[0].address);

    await this.makeMarkers(markers, mapInstance, bounds);

    // try to center map to show all bounds(markers)
    if (markers.length > 0) {
      mapInstance.panTo(bounds.getCenter());
    }
  };

  makeMarkers = async (markers, mapInstance, bounds) => {
    if (!isEmpty(markers)) {
      markers.map((marker, index) => {
        this.setPosition(marker, mapInstance, bounds, index);
      });
    }
  };

  setPosition = async (marker, mapInstance, bounds, index) => {
    if (checkIfAddressIsURL(marker.address)) {
      this.setPositionFromURL(marker, mapInstance, bounds, index);
    } else {
      await this.setPositionFromAddress(marker, mapInstance, bounds, index);
    }
  };

  setPositionFromURL(marker, mapInstance, bounds, index) {
    const latlng = getCoordinatesForURL(marker.address);

    this.drawMarker(latlng, marker, mapInstance, bounds, index);
  }

  async setPositionFromAddress(marker, mapInstance, bounds, index) {
    const latlng = await getCoordinatesForAddress(marker.address);

    this.drawMarker(latlng, marker, mapInstance, bounds, index);
  }

  handleZoom = (map) => {
    const { markers, mapZoom } = this.state.mapSettings;

    // need to reset zoom to default if single marker, because after fitBounds zoom increased to max zoom - 21
    const currentZoom = map.getZoom();

    // when we have multiple markers we need set zoom which calculated by Google Maps, to show all possible markers
    const zoomToBeSaved = markers.length > 1 ? currentZoom : mapZoom;

    map.setZoom(zoomToBeSaved);
  };

  moveMapCenterToFirstMarkerWhenMarkersAreTooFarAway = (map, marker) => {
    // check if marker in map viewport (should be checked after all markers placed)
    if (!map.getBounds().contains(marker.getPosition())) {
      map.panTo(this.state.firstMarkerPosition);
    }
  };

  drawMarker = (latlng, marker, mapInstance, bounds, index) => {
    const { google } = window;

    const newMarker = new google.maps.Marker({
      map: mapInstance,
      position: latlng,
      title: marker?.title || '',
      icon: MARKER_ICONS[marker?.markerType] || MARKER_ICONS.default,
      zIndex: index || 1,
    });

    const infoWindowContent = `<div class="map-marker"><div class="map-marker__title">${marker.title || ''}</div><div class="map-marker__desc">${marker.description || ''}</div></div>`;

    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent,
      maxWidth: 648,
    });

    newMarker.addListener('click', () => {
      infoWindow.open({
        anchor: newMarker,
        map: mapInstance,
      });
    });

    if (bounds) {
      mapInstance.setOptions({ maxZoom: DEFAULT_MAP_ZOOM });
      bounds.extend(latlng);
      mapInstance.fitBounds(bounds);
      mapInstance.setOptions({ maxZoom: MAX_MAP_ZOOM });
    }

    this.moveMapCenterToFirstMarkerWhenMarkersAreTooFarAway(mapInstance, newMarker);
    this.handleZoom(mapInstance);
  };
}

export default GoogleMap;
