import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { VIDEO_PROVIDER_NAMES } from '../../../constants';
import dom from '../../wrapper/DomWrapper';

import { VideoDailymotion, VideoVimeo, VideoYouTube } from './Providers';

class VideoFactory {
  init(video) {
    if (!video) return null;

    this.selector = video;
    this.settings = JSON.parse(get(this.selector, 'dataset.settings', {}));

    if (isEmpty(this.settings)) return null;

    const { provider, isSliderBg, hash } = this.settings;

    if (isSliderBg) {
      const elSlide = dom.getElement(`.slick-slide-${hash}.slick-cloned`);

      if (elSlide && elSlide.contains(this.selector)) return null;
    }

    switch (provider) {
      case VIDEO_PROVIDER_NAMES.YOUTUBE: {
        return new VideoYouTube(this.selector, this.settings);
      }
      case VIDEO_PROVIDER_NAMES.VIMEO: {
        return new VideoVimeo(this.selector, this.settings);
      }
      case VIDEO_PROVIDER_NAMES.DAILYMOTION: {
        return new VideoDailymotion(this.selector, this.settings);
      }
      case VIDEO_PROVIDER_NAMES.DAILYMOTION_FIX: {
        return new VideoDailymotion(this.selector, this.settings);
      }
      default: {
        return null;
      }
    }
  }
}

export default VideoFactory;
