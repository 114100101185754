const FINISHED_CLASS_NAME = '_finished';
const DAYS_SELECTOR = '[data-number="days"] .public-DraftStyleDefault-ltr [data-text="true"]';
const HOURS_SELECTOR = '[data-number="hours"] .public-DraftStyleDefault-ltr [data-text="true"]';
const MINUTES_SELECTOR = '[data-number="minutes"] .public-DraftStyleDefault-ltr [data-text="true"]';
const SECONDS_SELECTOR = '[data-number="seconds"] .public-DraftStyleDefault-ltr [data-text="true"]';

export {
  FINISHED_CLASS_NAME,
  DAYS_SELECTOR,
  HOURS_SELECTOR,
  MINUTES_SELECTOR,
  SECONDS_SELECTOR,
};
