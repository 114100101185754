// eslint-disable-next-line import/prefer-default-export
export const ERROR_MESSAGES = {
  default: 'sh.vr.message.default',
  required: 'sh.vr.message.required',
  requiredPhone: 'sh.vr.message.required-phone',
  requiredEmail: 'sh.vr.message.requiredEmail',
  phoneTooLong: 'sh.vr.message.too-long-phone',
  address: 'sh.vr.message.address',
  card: 'sh.vr.message.card',
  city: 'sh.vr.message.city',
  domainUrl: 'sh.vr.message.domainUrl',

  email: 'sh.vr.message.email',
  emailLessLength: 'sh.vr.message.email-less-length',
  emailMaxLength: 'sh.vr.message.email-max-length',

  firstname: 'sh.vr.message.firstname',
  lastname: 'sh.vr.message.lastname',
  name: 'sh.vr.message.name',
  surname: 'sh.vr.message.surname',
  subject: 'sh.vr.message.subject',
  password: 'sh.vr.message.password',
  phone: 'sh.vr.message.phone',
  state: 'sh.vr.message.state',
  url: 'sh.vr.message.url',
  zip: 'sh.vr.message.zip',
  emptyForm: '--form-error-text',
  captchaError: 'sh.vr.message.captchaError',
  twitter: 'sh.vr.message.twitter',
  facebook: 'sh.vr.message.facebook',
  number: 'sh.vr.message.number',
  currency: 'sh.vr.message.currency',
};

export const DATE_FORMATS = {
  EN_US: 'Month/Day/Year',
  EN_GB: 'Day/Month/Year',
};
