import getStateValue from './getStateValue';

/**
 *
 * @returns {boolean}
 */
const checkProjectWithAnimation = () => {
  const animation = getStateValue('settings.animation');

  return !!animation?.enabled && !!animation?.value;
};

export default checkProjectWithAnimation;
