/* eslint-disable camelcase */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import dom from '../../wrapper/DomWrapper';

import { PAYPAL_PRODUCT_BUTTON } from './constants';
import { postPayPalProductRequest } from './utils';

class PaypalProductItem {
  init = (payPalProductSelector) => {
    this.selector = payPalProductSelector;
    this.settings = JSON.parse(get(this.selector, 'dataset.settings', {}));
    this.elButtons = dom.getCollection(`${PAYPAL_PRODUCT_BUTTON}`, this.selector);

    if (isEmpty(this.elButtons)) return;

    this.elButtons.forEach((button) => dom.on(
      button,
      'click',
      this.handleClick(button, this.settings)
    ));
  };

  // eslint-disable-next-line class-methods-use-this
  handleClick = (button, settings) => () => {
    const {
      paypalAccount,
      currencyCode,
      priceProduct,
      titleProduct,
      isBackup,
    } = settings;

    if (isBackup) return;

    const currencyCodeProduct = currencyCode.toUpperCase();

    if (!paypalAccount) return;

    postPayPalProductRequest(
      {
        business: paypalAccount,
        cmd: '_cart',
        add: 1,
        item_name: titleProduct,
        amount: priceProduct,
        currency_code: currencyCodeProduct,
      }
    );
  };
}

export default PaypalProductItem;
