import clone from 'lodash/clone';

import enEditor from './entries/en';
import sharedEn from './entries/shared';

const getTranslations = (lang = 'en') => {
  const translations = {
    en: { ...enEditor, ...sharedEn },
  };

  return clone(translations[lang]);
};

export const i18next = {
  dictionary: getTranslations(),
  t(key) {
    return this.dictionary[key] || key;
  },
};

export default getTranslations;
