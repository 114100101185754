import animateScroll from '../../../helpers/animateScroll';
import dom from '../../../wrapper/DomWrapper';
import triggerLoadLazyImages from '../../LazyLoad/utils/triggerLoadLazyImages';

// eslint-disable-next-line import/no-cycle
import { getHeaderOffset } from './index';

const { getCleanUrl } = require('../../../../helper/urls/convertSrcToCorrect');

export default async () => {
  const { hash } = dom.window.location;

  if (!hash || !hash.startsWith('#s-')) return;

  const cleanHash = getCleanUrl(hash);

  const elSection = dom.getElement(cleanHash);

  if (!elSection) return;

  dom.window.scrollTo(0, 0);
  await triggerLoadLazyImages();

  const endPosition = elSection.offsetTop;
  const headerOffset = getHeaderOffset();
  const position = endPosition - headerOffset;

  animateScroll(position, {
    duration: 100,
  });
};
