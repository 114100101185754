import { ASPECT_RATIO } from '../constants';

/**
 *
 * @param {string=} imageRatio
 * @returns {{paddingTop: string, height: number}}
 */
const getBackground = (imageRatio) => {
  const aspectRatioFloat = ASPECT_RATIO[imageRatio] || 1;

  return {
    height: 0,
    paddingTop: `calc(100% / ${aspectRatioFloat})`,
  };
};

export default getBackground;
