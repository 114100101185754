import { REST_IMAGES_MAX_SIZE } from '../constants';

/**
 *
 * @param {number} index
 * @returns {boolean}
 */
const checkLastRestImage = (index) => index === (REST_IMAGES_MAX_SIZE - 1);

export default checkLastRestImage;
