import isEmpty from 'lodash/isEmpty';
import Macy from 'macy';

import LAYOUT_TYPE from '../../../components/Gallery/constants';
import {
  adjustToDevice, DEVICE_SIZES, DEVICE_TYPES, getDeviceRatio, GRID_SIZE,
} from '../../../device';
import { getDeviceType } from '../../helpers/browser';
import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import { setGalleryImageBorderSettings } from '../Image/BorderSettings';
import { LAZY_LOADED_EVENT } from '../LazyLoad';
import LightBox from '../Lightbox';

import { GALLERY_CLONED, LIGHT_BOX_SELECTOR } from './constants';

class Gallery extends WidgetWrapper {
  init = () => {
    const elGalleryWidgetList = dom.getCollection(this.selector);

    if (isEmpty(elGalleryWidgetList)) return;

    this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);
    this.device = null;

    // eslint-disable-next-line default-param-last
    elGalleryWidgetList.forEach((gallery = {}, index) => {
      const {
        widgetId = null,
        settings,
      } = gallery.dataset;

      if (widgetId && settings) {
        const isCloned = !!gallery.closest('.slick-cloned');
        const uniqueWidgetId = isCloned ? `${widgetId}-${GALLERY_CLONED}-${index}` : widgetId;
        const parsedSettings = JSON.parse(settings);
        const {
          layoutType, hasLightbox, amountInRow, itemSize,
        } = parsedSettings;
        const device = getDeviceType();
        const adjustSize = adjustToDevice(itemSize, device);
        // eslint-disable-next-line max-len
        const deviceAmountInRow = Math.ceil((adjustSize / GRID_SIZE) * amountInRow * getDeviceRatio(device, DEVICE_TYPES));

        if (gallery.firstChild.style) {
          // eslint-disable-next-line no-param-reassign
          gallery.firstChild.style['grid-template-columns'] = `repeat(${deviceAmountInRow},1fr)`;
        }

        if (isCloned) gallery.classList.add(GALLERY_CLONED, `${GALLERY_CLONED}-${index}`);

        if (hasLightbox) {
          this.lightBox.galleryModalData[uniqueWidgetId] = parsedSettings;
          this.connectGalleryToLightBox(uniqueWidgetId);
        }

        if (layoutType === LAYOUT_TYPE.masonry) this.initMasonry(gallery, parsedSettings, deviceAmountInRow);
        if (layoutType === LAYOUT_TYPE.grid) this.initGrid(gallery, parsedSettings);
      }
    });
  };

  connectGalleryToLightBox = (galleryId) => {
    const {
      navButtonsAttributes,
      modalsParams,
    } = this.lightBox.getLightBoxAttributes();

    const galleryToConnect = galleryId
      ? { [galleryId]: this.lightBox.galleryModalData[galleryId] }
      : this.lightBox.galleryModalData;

    this.lightBox.addLightBoxToGalleryPhotos(
      galleryToConnect,
      navButtonsAttributes,
      modalsParams
    );
  };

  initMasonry = (gallery, parsedSettings, deviceAmountInRow) => {
    const { amountInRowMobile, padding } = parsedSettings || {};
    const elGalleryWrap = gallery.children[0];
    const instanceMacy = this.createMasonryLayout(elGalleryWrap, deviceAmountInRow, amountInRowMobile, padding);

    for (const elGalleryItem of elGalleryWrap.children) {
      const elPicture = dom.getElement('picture', elGalleryItem);

      elPicture.addEventListener(LAZY_LOADED_EVENT, () => {
        const elImg = dom.getElement('img', elPicture);

        if (elImg) setGalleryImageBorderSettings(elImg);

        instanceMacy.recalculate(true, true);
        dom.showOpacity(elGalleryItem.firstChild);
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  createMasonryLayout = (el, amountInRow, amountInRowMobile, padding) => {
    const breakAt = amountInRowMobile.reduce((acc, curr) => {
      const { count, device } = curr;
      const [, maxQuery] = DEVICE_SIZES[device];

      if (!maxQuery) return acc;

      return {
        ...acc,
        [maxQuery]: Math.ceil(count),
      };
    }, {});

    return new Macy({
      container: el,
      trueOrder: true,
      columns: amountInRow,
      breakAt,
      margin: {
        x: padding,
        y: padding,
      },
    });
  };

  // eslint-disable-next-line class-methods-use-this
  initGrid = (gallery) => {
    const elGalleryWrap = gallery.children[0];

    for (const elGalleryItem of elGalleryWrap.children) {
      const elPicture = dom.getElement('picture', elGalleryItem);

      elPicture.addEventListener(LAZY_LOADED_EVENT, () => {
        const elImg = dom.getElement('img', elPicture);

        if (!elImg) return;

        setGalleryImageBorderSettings(elImg);
      });
    }
  };
}

export default Gallery;
