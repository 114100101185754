import animateScroll from '../../helpers/animateScroll';
import browser from '../../helpers/browser';
import dom from '../../wrapper/DomWrapper';

let button = null;
let scrollY = 0;
let containerHeight = 0;
const minScrollFactor = 0.5;

const isVisible = () => scrollY > containerHeight * minScrollFactor;

const onScroll = () => {
  scrollY = dom.window.pageYOffset;
  containerHeight = dom.window.innerHeight;

  if (isVisible()) {
    dom.addClass(button, 'back-to-top_visible');
  } else {
    dom.removeClass(button, 'back-to-top_visible');
  }
};

const BackToTop = () => {
  button = dom.getElement('.back-to-top');

  if (!button) return;

  if (browser.isInFrame) {
    dom.removeElement(button);

    return;
  }

  scrollY = dom.window.pageYOffset;
  containerHeight = dom.window.innerHeight;

  if (isVisible()) dom.addClass(button, 'back-to-top_visible');

  scrollY = dom.window.scrollY;
  containerHeight = dom.window.innerHeight;
  dom.on(button, 'click', () => animateScroll(0, {
    scrollY,
  }));
  dom.on(dom.document, 'scroll', onScroll);
};

export default BackToTop;
