import lazyLoad from '../../../LazyLoad';

const addGridItemImages = (element, { previewImageUrl }) => {
  if (!previewImageUrl) return;

  setTimeout(() => {
    lazyLoad();
  }, 0);
};

export default addGridItemImages;
