import { convertSrcToCorrect } from '../../../../helper/urls';
import { NO_SRC } from '../../../../helper/urls/constants';
import getStateValue from '../../../helpers/getStateValue';

import fixContainImages from './fixContainImages';
import fixSvgImages from './fixSvgImages';

const staticApi = getStateValue('apiUrls', {});
const defaultImage = convertSrcToCorrect(NO_SRC, staticApi);

export {
  fixSvgImages,
  fixContainImages,
};

export default defaultImage;
