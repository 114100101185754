import {
  DESKTOP,
  LAPTOP,
  PHONE,
  QUAD,
  TABLET,
  ULTRA,
} from '../../device';

export const DEVICE_PRIORITY = [ULTRA, QUAD, DESKTOP, LAPTOP, TABLET, PHONE];
