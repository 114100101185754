import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

class Table extends WidgetWrapper {
  init = () => {
    const tablesDom = dom.getCollection(this.selector);

    for (const table of tablesDom) {
      const {
        settings,
      } = table.dataset;

      const { numberOfColumn } = JSON.parse(settings);

      if (!numberOfColumn) {
        return;
      }

      const tableWidth = table.clientWidth;
      const cellWidth = tableWidth / numberOfColumn;
      const cells = dom.getCollection('td', table);

      for (const cell of cells) {
        cell.style.width = `${cellWidth}px`;
        cell.style.maxWidth = `${cellWidth}px`;
      }
    }
  };
}

export default Table;
