import isElement from 'lodash/isElement';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';

import checkProjectWithAnimation from '../../helpers/checkProjectWithAnimation';
import dom from '../../wrapper/DomWrapper';

import {
  DAYS_SELECTOR,
  FINISHED_CLASS_NAME,
  HOURS_SELECTOR,
  MINUTES_SELECTOR,
  SECONDS_SELECTOR,
} from './constants';
import { getRemainingTime, toUtc, updateNumberHtml } from './utils';

class Countdown {
  constructor(widget) {
    this.widget = widget;
  }

  init = () => {
    if (!isElement(this.widget)) return;

    const { settings: dataSettings } = this.widget.dataset;
    const settings = !isNil(dataSettings) ? JSON.parse(dataSettings) : {};

    this.dueDate = new Date(settings?.dueDate).getTime();

    this.days = dom.getCollection(DAYS_SELECTOR, this.widget);
    this.hours = dom.getCollection(HOURS_SELECTOR, this.widget);
    this.minutes = dom.getCollection(MINUTES_SELECTOR, this.widget);
    this.seconds = dom.getCollection(SECONDS_SELECTOR, this.widget);

    this.isLoaded = false;
    this.withAnimation = checkProjectWithAnimation();

    this.initWidget();
  };

  initWidget = () => {
    if (isNaN(this.dueDate)) return;

    const now = toUtc();
    const distance = this.dueDate - now;

    if (distance < 0) {
      this.finishCountdown();

      return;
    }

    this.drawNumbers(getRemainingTime(distance));
    this.countdownTimer();
  };

  countdownTimer = () => {
    this.intervalId = setInterval(() => {
      const now = toUtc();
      const distance = this.dueDate - now;

      if (!this.withAnimation && !this.isLoaded && distance >= 0) {
        dom.showOpacity(this.widget);
        this.isLoaded = true;
      }

      if (distance < 0) {
        this.finishCountdown();
        clearInterval(this.intervalId);

        return;
      }

      this.drawNumbers(getRemainingTime(distance));
    }, 1000);
  };

  drawNumbers = ({
    days, hours, minutes, seconds,
  }) => {
    updateNumberHtml(days, this.days);
    updateNumberHtml(hours, this.hours);
    updateNumberHtml(minutes, this.minutes);
    updateNumberHtml(seconds, this.seconds);
  };

  finishCountdown = () => {
    dom.addClass(this.widget, FINISHED_CLASS_NAME);

    if (this.withAnimation) return;

    setTimeout(() => {
      dom.showOpacity(this.widget);
    }, 150);
  };
}

export default Countdown;
