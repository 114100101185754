import isFunction from 'lodash/isFunction';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

import { isNumericId } from './utils';

class EcwidCart {
  constructor(context) {
    this.context = context;
  }

  checkProductId = (id) => {
    if (isNumericId(id)) throw new Error('Incorrect product id type');
  }

  itemAdd = async (
    productId,
    quantity = 1,
    options = {},
    cb = () => null
  ) => {
    this.checkProductId(productId);

    const cart = await this.getCart();

    cart.addProduct({
      id: productId,
      quantity,
      options,
      callback: cb,
    });
  }

  itemRemove = async (id) => {
    this.checkProductId(id);

    const cart = await this.getCart();

    cart.get(
      ({ items = [] }) => {
        const removedItemIndex = findIndex(items, { product: { id } });

        if (removedItemIndex !== -1) cart.removeProduct(removedItemIndex);
      }
    );
  }

  itemGetQuantity = async (id) => {
    this.checkProductId(id);

    const cart = await this.getCart();

    return new Promise(((resolve) => {
      cart.get(
        ({ items = [] }) => {
          const quantity = items.reduce(
            (acc, item) => {
              const currentId = get(item, ['product', 'id'], null);

              if (currentId !== id) return acc;

              const currentQuantity = get(item, ['quantity'], 0);

              return acc + currentQuantity;
            },
            0
          );

          resolve(quantity);
        }
      );
    }));
  }

  getCart = async () => {
    await this.context.isReady();

    return this.context.ecwid.Cart;
  }

  getItems = async () => {
    const cart = await this.getCart();

    return new Promise(((resolve) => {
      cart.get(({ items = [] }) => resolve(items));
    }));
  }

  getTotalQuantity = async () => {
    const cart = await this.getCart();

    return new Promise(((resolve) => {
      cart.get(({ productsQuantity = 0 }) => resolve(productsQuantity));
    }));
  }

  onChange = {
    add: async (cb) => {
      if (!isFunction(cb)) return;

      await this.context.isReady();
      this.context.ecwid.OnCartChanged.add(cb);
    },
    clear: async () => {
      await this.context.isReady();
      this.context.ecwid.OnCartChanged.clear();
    },
  }
}

export default EcwidCart;
