import isFunction from 'lodash/isFunction';

import { findDevice } from '../../../helpers/browser';
import dom from '../../../wrapper/DomWrapper';
import defaultImage from '../utils';

/**
 *
 * @param {HTMLElement} elImage
 * @param {HTMLElement} elParent
 * @param {function=} cb
 */
const pictureErrorHandler = (elImage, elParent, cb) => {
  if (!elImage || !elParent) return;

  try {
    const sizes = JSON.parse(elImage.closest('picture')?.dataset?.sizes || {});
    const deviceType = findDevice(dom.window.innerWidth, sizes);
    const currentSource = dom.getElement(`source[data-device=${deviceType}]`, elParent) || dom.getElement('source', elParent);

    if (currentSource) {
      currentSource.srcset = defaultImage;
    }
  } catch (error) {
    console.error(error);
  }

  if (isFunction(cb)) cb();
};

export default pictureErrorHandler;
