import entries from 'lodash/entries';

import generatePictureResizeSrc from './generatePictureResizeSrc';

/**
 *
 * @param {string} src
 * @param {object} sizes
 * @param {object} dynamic
 * @param {object} options
 * @returns {object} resizeSourceList
 */
const generatePictureResizeSourceList = (
  src,
  sizes = {},
  dynamic,
  options
) => entries(sizes).reduce((acc, [device, media]) => {
  const layout = `image_${device}`;
  const resizeSrc = generatePictureResizeSrc(src, layout, dynamic, options);

  return {
    ...acc,
    [device]: {
      srcSet: resizeSrc,
      media,
    },
  };
}, {});

export default generatePictureResizeSourceList;
