import { IN_VIEW_CLASS_NAME } from '../../../../../constants';
import checkProjectWithAnimation from '../../../../helpers/checkProjectWithAnimation';
import { removeInViewWait } from '../../../../helpers/inView';
import dom from '../../../../wrapper/DomWrapper';

/**
 *
 * @param {HTMLElement} elContainer
 */
const mountWidgetsInView = (elContainer) => {
  const withAnimation = checkProjectWithAnimation();

  if (!withAnimation) return;

  const collection = dom.getCollection(`.items-view-widget.${IN_VIEW_CLASS_NAME}`, elContainer) || [];
  const elements = [...collection];

  if (elements.length === 0) return;

  removeInViewWait(elements);
};

export default mountWidgetsInView;
