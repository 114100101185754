const getGridImageTransform = require('./getGridImageTransform');
const getListImageTransform = require('./getListImageTransform');

/**
 *
 * @param {Object=} options
 * @param {string} options.layout
 * @param {string=} options.layoutStyle
 * @param {number=} options.layoutRowLimit
 * @param {string=} options.layoutWidth
 * @param {number} options.layoutAspectRatio
 * @param {{ width: number, height: number}} options.original
 * @returns {Object}
 */
const getPreviewImageTransform = ({
  layout,
  layoutStyle,
  layoutRowLimit,
  layoutWidth,
  layoutAspectRatio,
  original,
}) => {
  if (layout === 'grid') {
    return getGridImageTransform({
      rowLimit: layoutRowLimit,
      aspectRatio: layoutAspectRatio,
      original,
    });
  }

  if (layout === 'list') {
    return getListImageTransform({
      style: layoutStyle,
      aspectRatio: layoutAspectRatio,
      pageWidth: layoutWidth,
      original,
    });
  }

  return {};
};

module.exports = getPreviewImageTransform;
