import BookingCatalogPage from '../../implementation/Booking/CatalogPage';
import BookingServicePage from '../../implementation/Booking/ServicePage';
import dom from '../../wrapper/DomWrapper';
import { checkServicePage, getServiceId } from '../utils';
import booking from '..';

import {
  BOOKING_CATALOG_PAGE_WRAPPER_ID,
  BOOKING_SERVICE_PAGE_WRAPPER_ID,
  PAGE_TYPE,
} from './constants';

const WIDGET_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.BOOKING_STORE_PAGE, new BookingCatalogPage()],
  [PAGE_TYPE.BOOKING_SERVICE_PAGE, new BookingServicePage()],
]);

const WRAPPER_ID_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.BOOKING_STORE_PAGE, BOOKING_CATALOG_PAGE_WRAPPER_ID],
  [PAGE_TYPE.BOOKING_SERVICE_PAGE, BOOKING_SERVICE_PAGE_WRAPPER_ID],
]);

class Router {
  constructor() {
    this.href = dom.window.location.href;
    this.type = this.getPageType();
  }

  init = () => {
    if (!WIDGET_BY_PAGE_TYPE.has(this.type)) return;

    const page = WIDGET_BY_PAGE_TYPE.get(this.type);

    this.showPage();
    page.init({ id: this.getServiceId() });
  };

  getPageType = () => {
    const isServicePage = checkServicePage(this.href, booking.provider.bookingTag);

    if (isServicePage) return PAGE_TYPE.BOOKING_SERVICE_PAGE;

    const elStorePage = dom.getElement(`#${BOOKING_CATALOG_PAGE_WRAPPER_ID}`);

    if (elStorePage) return PAGE_TYPE.BOOKING_STORE_PAGE;

    return null;
  };

  getServiceId = () => {
    if (this.type !== PAGE_TYPE.BOOKING_SERVICE_PAGE) return null;

    return getServiceId(this.href);
  };

  // eslint-disable-next-line consistent-return
  showPage = () => {
    if (!WRAPPER_ID_BY_PAGE_TYPE.has(this.type)) return null;

    const currentPageWrapperId = WRAPPER_ID_BY_PAGE_TYPE.get(this.type);

    dom.show(dom.getElement(`#${currentPageWrapperId}`));
  };
}

export default Router;
