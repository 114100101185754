/**
 *
 * @param {string} string
 * @returns {boolean}
 */
const checkValidUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch {
    return false;
  }

  return !!url;
};

export default checkValidUrl;
