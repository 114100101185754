/* eslint-disable camelcase */
const cutNumber = require('../cutNumber');
const { FULL_IMAGE_SETTINGS } = require('./constants');

/**
 *
 * @param {Object} options
 * @param {{ width: number, height: number}} options.original
 * @returns {Object}
 */
const getFullImageTransform = ({ original }) => {
  const {
    width: originalWidth,
    height: originalHeight,
  } = original || {};

  return Object.entries(FULL_IMAGE_SETTINGS).reduce((transform, [device, { width: wrapWidth }]) => {
    const wrapHeight = cutNumber(wrapWidth / (originalWidth / originalHeight));

    if (originalWidth < wrapWidth || originalHeight < wrapHeight) return transform;

    return {
      ...transform,
      [`image_${device}`]: {
        wrap_height: wrapHeight,
      },
    };
  }, {});
};

module.exports = getFullImageTransform;
