import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { DESKTOP } from '../../../../../device';

/**
 * Prepare items to rows
 * @param {array} items
 * @param {Object} options
 * @param {string} options.device
 * @param {number} options.totalRow
 * @param {{
 *   quad: [number],
 *   desktop: [number],
 *   laptop: [number],
 *   tablet: [number],
 *   phone: [number],
 * }} options.rows
 * @returns {[[items]]} rows of items
 */
const prepareItems = (items = [], {
  device = DESKTOP,
  totalRow = 0,
  rows = {},
}) => {
  const deviceRows = rows[device];

  if (!isArray(deviceRows)) return items;

  const emptyRows = new Array(totalRow).fill(0);
  const { data } = emptyRows.reduce((acc, value, index) => {
    const itemsCountInRow = deviceRows[index];
    const endIndex = acc.startIndex + itemsCountInRow;
    const itemsInRow = items.slice(acc.startIndex, endIndex);

    return {
      ...acc,
      startIndex: endIndex,
      data: [
        ...acc.data,
        itemsInRow,
      ],
    };
  }, {
    startIndex: 0,
    data: [],
  });

  return data.filter((row) => !isEmpty(row));
};

export default prepareItems;
