import checkValidUrl from '../../../helper/urls/checkValidUrl';
import booking from '../../booking';
import dom from '../../wrapper/DomWrapper';

class ServicePage {
  // eslint-disable-next-line class-methods-use-this
  init = ({ id }) => {
    const { bookingService, bookingId } = booking.provider;
    const url = `${bookingService}?site=${bookingId}&serviceId=${id}`;
    const isValidUrl = checkValidUrl(url);
    const elWrapper = dom.getElement('.booking-iframe');

    if (!isValidUrl || !elWrapper) return;

    const elIframe = dom.createElement('iframe');
    const settings = elWrapper.dataset.settings ? JSON.parse(elWrapper.dataset.settings) : {};
    const { bookingPageUrl } = settings || {};

    dom.on(dom.window, 'message', (e) => {
      const { data } = e || {};

      if (data?.type !== 'close') return;
      if (!bookingPageUrl) return;

      dom.window.location.assign(`/${bookingPageUrl}/`);
    });

    dom.on(elIframe, 'load', () => {
      dom.removeClass(elWrapper, 'spinner');
    });

    elIframe.src = url;

    elWrapper.append(elIframe);
  };
}

export default ServicePage;
