import dom from '../../wrapper/DomWrapper';
import { LINK_PROTOCOLS } from './constants';
import { getAnchorTargetObj, sendMessage } from './utils';

const TemplatePreview = (isBackup) => {
  const header = dom.getElement('.main__header');
  const isFixedHeader = dom.hasClass(header, 'header_fixed-wrapper');
  let scrollToIframe = false;
  const queryParams = window?.location?.search;
  const isSiteWizard = queryParams?.includes('sitewizardId');

  if (isSiteWizard) {
    sendMessage({
      type: 'location',
      pathname: dom.window?.location?.pathname,
    });

    const allLinks = document.querySelectorAll('a:not([href^="mailto:"]):not([href^="tel:"]):not([href^="#"])');

    [...allLinks].forEach((el) => {
      const href = el?.href;

      if (href) el.setAttribute('href', href + queryParams);
    });
  }

  if (!isBackup) {
    dom.addClass(document.body, 'isTemplate');

    dom.window.onbeforeunload = () => {
      const protocol = dom.document.activeElement?.protocol;

      if (LINK_PROTOCOLS.includes(protocol)) return;

      sendMessage({
        type: 'clearHeight',
        height: null,
      });

      sendMessage({
        type: 'height',
        height: null,
        cycle: 'unload',
      });
    };

    dom.window.addEventListener('click', (e) => {
      const targetObj = getAnchorTargetObj(e.target);

      sendMessage({
        type: 'iframeAnchorClick',
        ...targetObj,
      });
    });

    window.addEventListener('scroll', () => {
      if (scrollToIframe) return;

      sendMessage({
        type: 'onScroll',
        scrollTop: dom.window.pageYOffset,
      });
    });
  }

  dom.window.addEventListener('message', (message) => {
    const {
      data: { type, scrollTop, fullStoryAnalyticUrl },
    } = message;

    switch (type) {
      case 'scrolling':
        sendMessage(
          {
            type: 'height',
            height: dom.document.body.clientHeight,
            isFixedHeader,
          },
          message.origin
        );

        break;
      case 'onScroll':
        if (!scrollToIframe) {
          scrollToIframe = true;
        } else {
          dom.window.scroll(0, scrollTop);
        }

        break;
      case 'stopScroll':
        scrollToIframe = false;

        break;
      case 'fullStoryAnalyticUrl':
        const elHead = document.head;
        const elScript = document.createElement('script');

        elScript.async = true;

        elScript.src = fullStoryAnalyticUrl;

        elHead.append(elScript);

        break;
      default:
        break;
    }
  });
};

export default TemplatePreview;
