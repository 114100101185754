import Booking from '../../../booking';
import dom from '../../../wrapper/DomWrapper';
import { SingleBookingProductWidget } from '../../Booking';
import { getProductMinHeight } from '../../Booking/utils';

import { mountItemsInView, mountWidgetsInView } from './utils';

const preInit = async () => ({
  provider: Booking.provider,
});

const afterInit = (elContainer) => {
  mountWidgetsInView(elContainer);
};

const preRender = (product, index, elContainer, {
  itemSettings,
} = {}) => {
  product.updateSettings(itemSettings);

  return {
    product,
  };
};

const postRender = (element, product, index) => {
  new SingleBookingProductWidget(dom.getElement('div.booking', element), product).init(index);

  return element;
};

// eslint-disable-next-line func-style
function didMount(elContainer) { // don't use arrow func, this func called with context
  const { imageShape, imageRatio } = this.itemSettings;
  const widgetWidth = this.getElementWidth();
  const minHeight = getProductMinHeight({
    widgetWidth,
    imageShape,
    imageRatio,
  });

  dom.updateStyle(elContainer, { minHeight: `${minHeight}px` });
  mountItemsInView(elContainer);
}

export default {
  preInit,
  afterInit,
  preRender,
  postRender,
  didMount,
};
