import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import checkIsTheSameOrigin from './checkIsTheSameOrigin';
import checkIsTheSamePathName from './checkIsTheSamePathName';

/**
 * Util for check if href origin/pathName is equals to current page origin/pathName
 * @param {string} href
 * @return {boolean} isAnchorToCurrentPage
 */
export default (href) => {
  if (!isString(href) || isEmpty(href)) return false;

  if (href.startsWith('#') || href.startsWith('/#')) return true;

  const isHrefHasSameOrigin = checkIsTheSameOrigin(href);
  const isHrefHasSamePathName = checkIsTheSamePathName(href);

  return !!(isHrefHasSameOrigin && isHrefHasSamePathName);
};
