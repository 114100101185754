import dom from '../../../wrapper/DomWrapper';
import defaultImage from '../utils';

import errorHandler from './errorHandler';

const logoErrorHandler = () => {
  const elCroppedImage = dom.getElement('.logo__wrap .cropped-image');
  const elImage = dom.getElement('img', elCroppedImage);

  if (!elImage) return;

  const onError = (el) => {
    // eslint-disable-next-line no-param-reassign
    el.src = defaultImage;
    dom.addClass(elCroppedImage, 'picture-wrap_unavailable');
  };

  errorHandler('.logo__wrap', onError);

  elImage.src = elImage.dataset.src;
};

export default logoErrorHandler;
