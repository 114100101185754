import dom from '../../wrapper/DomWrapper';

class TwitterList {
  constructor(selector, settings) {
    this.selector = selector;
    this.settings = settings;
  }

  init() {
    const {
      source, tweetLimit, noheader, transparent,
    } = this.settings;
    const elTweetList = dom.createElement('div');

    dom.addHtml(elTweetList, `
      <a 
        class="twitter-timeline" 
        data-chrome="${noheader && 'noheader'} ${transparent && 'transparent'} nofooter"
        ${tweetLimit && `data-tweet-limit="${tweetLimit}"`}
        href="https://twitter.com/${source}?ref_src=twsrc%5Etfw"
      />
    `);

    this.selector.append(elTweetList);
  }
}

export default TwitterList;
