/* eslint-disable camelcase */
import { i18next } from '../../../i18n';
import { LAYOUT_GRID } from '../../ItemsView/constants';

const IMAGE_SHAPE_OPTIONS = {
  CIRCLE: 'CIRCLE',
};

const IMAGE_RATIO_OPTIONS = {
  SQUARE_1: 'SQUARE_1',
};

const DEFAULT_LAYOUT_SETTINGS = {
  grid: {
    items_per_row: 3,
    total_rows: 2,
  },
  list: {
    itemsCount: 2,
  },
  layoutType: LAYOUT_GRID,
  spacing: 30,
  margin: 0,
};

const DEFAULT_PRODUCT_STYLES = {
  backgroundColor: 'rgba(255,255,255, 0)',
  bgBorderColor: 'rgb(0,0,0)',
  bgBorderWidth: 0,
};

const DEFAULT_STYLES = {
  ...DEFAULT_PRODUCT_STYLES,
  itemBackgroundColor: 'rgba(255,255,255, 0)',
  itemBorderColor: 'rgb(0,0,0)',
  itemBorderWidth: 0,
};

const getDefaultStyle = (isCatalog, style) => ({
  ...isCatalog ? DEFAULT_STYLES : DEFAULT_PRODUCT_STYLES,
  ...style,
});

const getDefaultHoverEffect = () => ({
  hasEffect: true,
  hoverEffect: i18next.t('se.bo.booking_catalogue.design_hover_effect_highlight_border'),
});

const getDefaultStructure = () => ({
  showImage: true,
  showName: true,
  showShortDescription: true,
  showDescription: true,
  showPrice: true,
  showDuration: true,
  btnFullWidth: true,
  buttonText: i18next.t('se.bo.booking_catalogue.button_text'),
  hasEffect: getDefaultHoverEffect().hasEffect,
  hoverEffect: getDefaultHoverEffect().hoverEffect,
  imageShape: IMAGE_SHAPE_OPTIONS.CIRCLE,
  imageRatio: IMAGE_RATIO_OPTIONS.SQUARE_1,
});

const getDefaultCategory = () => ({
  label: i18next.t('se.bo.booking_catalogue.category_all_products'),
  value: 'defaultCategory',
});

export {
  IMAGE_SHAPE_OPTIONS,
  IMAGE_RATIO_OPTIONS,
  DEFAULT_LAYOUT_SETTINGS,
  getDefaultStyle,
  getDefaultStructure,
  getDefaultHoverEffect,
  getDefaultCategory,
};
