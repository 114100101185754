/**
 *
 * @param {number[]} size
 * @returns {`${string|string}${string}${string|string}`}
 */
const getPictureSourceMedia = (size = []) => {
  const [minWidth, maxWidth] = size;
  const withMinWidth = !!minWidth;
  const withMaxWidth = !!maxWidth;
  const withMinMaxWidth = withMinWidth && withMaxWidth;

  return `${withMinWidth ? `(min-width:${minWidth}px)` : ''}${withMinMaxWidth ? ' and ' : ''}${withMaxWidth ? `(max-width:${maxWidth}px)` : ''}`;
};

export default getPictureSourceMedia;
