import dom from '../../../wrapper/DomWrapper';
import { lazy, LAZY_LOADED_EVENT } from '../index';

const getLazyImages = () => {
  const lazyCollection = dom.getCollection('picture.lazy') || [];

  return [...lazyCollection].reduce((acc, elLazy) => {
    const elImg = dom.getElement('img', elLazy);
    const isLoaded = elImg?.complete && elImg?.naturalHeight !== 0;

    if (isLoaded
      || elLazy.closest('.slick-slide')
      || dom.hasClass(elLazy, 'lightbox__img')
    ) return acc;

    return [...acc, elLazy];
  }, []);
};

const triggerLoadLazyImages = () => new Promise((resolve) => {
  let loadedImagesLength = 0;
  const lazyImages = getLazyImages();
  const lazyImagesLength = lazyImages.length;

  if (lazyImagesLength === 0) return resolve();

  const onLoad = () => {
    loadedImagesLength += 1;

    if (loadedImagesLength === lazyImagesLength) resolve();
  };

  lazyImages.forEach((elLazy) => {
    dom.on(elLazy, LAZY_LOADED_EVENT, onLoad);

    const elSources = dom.getCollection('source', elLazy) || [];

    if (elSources.length === 0) return onLoad(elLazy);

    elSources.forEach((elSource) => {
      elSource.setAttribute('srcset', elSource.getAttribute('srcset'));
    });

    lazy.triggerLoad(elLazy);
  });
});

export default triggerLoadLazyImages;
