import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import Section from './Section';

class SectionWrapper extends WidgetWrapper {
  init = () => {
    const widgets = dom.getCollection(this.selector);

    [...widgets].forEach((widget) => {
      const instance = new Section(widget);

      instance.init();
    });
  };
}

export default SectionWrapper;
