import dom from '../../../wrapper/DomWrapper';

import errorHandler from './errorHandler';
import pictureErrorHandler from './pictureErrorHandler';

const sectionBgErrorHandler = () => {
  const selectorName = '.section_bg ._error-wrap';
  const onError = (elImage, elParent) => {
    pictureErrorHandler(elImage, elParent, () => {
      dom.addClass(elParent, 'picture-wrap_unavailable');
    });
  };

  errorHandler(selectorName, onError);
};

export default sectionBgErrorHandler;
