import { IN_VIEW_CLASS_NAME, IN_VIEW_ENTER_CLASS_NAME } from '../../../../../constants';
import checkProjectWithAnimation from '../../../../helpers/checkProjectWithAnimation';
import { connectInView } from '../../../../helpers/inView';
import dom from '../../../../wrapper/DomWrapper';

/**
 *
 * @param {HTMLElement} elContainer
 */
const mountBlogFeedInView = (elContainer) => {
  const withAnimation = checkProjectWithAnimation();

  if (!withAnimation) return;

  const elementsAnimation = [
    ...dom.getCollection('.blog-item__title', elContainer),
    ...dom.getCollection('.blog-item__create-date', elContainer),
    ...dom.getCollection('.editor__block', elContainer) || [],
    ...dom.getCollection('.video-container', elContainer) || [],
    ...dom.getCollection('.blog-item__divider', elContainer) || [],
    dom.getElement('.post-page__navigation', elContainer),
  ]
    .filter(Boolean)
    .filter((el) => !dom.hasClass(el, IN_VIEW_ENTER_CLASS_NAME));

  if (elementsAnimation.length === 0) return;

  for (const element of elementsAnimation) {
    dom.addClass(element, IN_VIEW_CLASS_NAME);
  }

  setTimeout(() => {
    connectInView(elementsAnimation);
  }, 0);
};

export default mountBlogFeedInView;
