import isEmpty from 'lodash/isEmpty';

import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import PanelWidget from './Panel';

class PanelWidgets extends WidgetWrapper {
  init = () => {
    const elWidgets = dom.getCollection(this.selector);

    if (isEmpty(elWidgets)) return;

    elWidgets.forEach((widget) => {
      this.widget = new PanelWidget(widget);
      this.widget.init();
    });
  };
}

export default PanelWidgets;
