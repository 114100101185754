import dom from '../../wrapper/DomWrapper';

const TAGS = [
  'description',
  'og:title',
  'og:description',
  'twitter:title',
  'twitter:description',
];

/**
 *
 * @param {string=} name
 * @param {string=} title
 * @param {string=} description
 */
const updateTitleDescription = ({ name, title, description }) => {
  const elTitle = dom.getElement('title');
  const elHead = dom.document.head;

  if (name || title) {
    if (elTitle) {
      elTitle.textContent = name || title;
    } else {
      elHead.insertAdjacentHTML('beforeend', `<title>${name || title}</title>`);
    }
  }

  TAGS.forEach((tagName) => {
    const isTitle = tagName.includes('title');
    const isDescription = tagName.includes('description');

    if (!isTitle && !isDescription) return;

    const content = isTitle ? title : description;

    if (!content) return;

    const key = tagName === 'description' ? 'name' : 'property';
    const elMeta = dom.getElement(`meta[${key}="${tagName}"]`);

    if (elMeta) {
      elMeta.setAttribute('content', content);
    } else {
      elHead.insertAdjacentHTML('beforeend', `<meta ${key}="${tagName}" content="${content}"></meta>`);
    }
  });
};

export default updateTitleDescription;
