/* eslint-disable camelcase */
const get = require('lodash/get');

const cutNumber = require('../cutNumber');
const { CONTENT_IMAGE_SETTINGS, DEVICES, LAYOUT_PADDING } = require('./constants');

/**
 *
 * @param {Object} options
 * @param {number} options.aspectRatio
 * @param {string} options.pageWidth
 * @param {{ width: number, height: number}} options.original
 * @returns {Object}
 */
const getCompactImageTransform = ({
  aspectRatio,
  pageWidth,
  original,
}) => {
  const {
    width: originalWidth,
    height: originalHeight,
  } = original || {};

  return DEVICES.reduce((transform, device) => {
    const imageWidth = get(CONTENT_IMAGE_SETTINGS, [device, pageWidth, 'default', 'width'])
      || get(CONTENT_IMAGE_SETTINGS, [device, 'width']);
    const imagePart = device === 'phone' ? 1 : 0.3;
    const wrapWidth = cutNumber((imageWidth * imagePart) - LAYOUT_PADDING);
    const wrapHeight = cutNumber(wrapWidth * aspectRatio);

    if (originalWidth < wrapWidth || originalHeight < wrapHeight) return transform;

    return {
      ...transform,
      [`image_${device}`]: {
        wrap_height: wrapHeight,
      },
    };
  }, {});
};

module.exports = getCompactImageTransform;
