const isEmpty = require('lodash/isEmpty');
const getFileExtension = require('./getFileExtension');

const NOT_RESIZABLE_TYPES = new Set(['svg', 'gif']);
const NOT_RESIZABLE_MIME_TYPES = new Set(['image/svg', 'image/svg+xml', 'image/gif']);

/**
 * Util for check if image can be resizable
 * @param {string} src
 * @param {string=} contentType
 * @returns {boolean} isImageResizable
 */
const checkImageResizable = (src, contentType) => {
  if (contentType) {
    return !NOT_RESIZABLE_MIME_TYPES.has(contentType);
  }

  const fileExtension = getFileExtension(src);

  return !isEmpty(fileExtension) && !NOT_RESIZABLE_TYPES.has(fileExtension);
};

module.exports = checkImageResizable;
