import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';

import { ECWID_DASH_PAGE_TYPE } from '../../../../../components/Ecommerce/constants';
import { NATIVE_EFFECT_CLASS_NAME } from '../../../../../components/Ecommerce/Ecwid/Custom/Catalog/constants/native';
import {
  ADD_TO_CART,
  ASPECT_RATIO,
  BACKGROUND_COLORS,
  GO_TO_CHECKOUT, OPEN_PRODUCT_PAGE,
} from '../../../../../components/Ecommerce/Ecwid/Custom/constants';
import ecommerce from '../../../../ecommerce/ecwid/custom';
import router from '../../../../ecommerce/ecwid/custom/router';
import browser from '../../../../helpers/browser';
import { lightness, toValidColor } from '../../../../helpers/color';
import { isCssVar } from '../../../../helpers/color/utils';
import getStateValue from '../../../../helpers/getStateValue';
import dom from '../../../../wrapper/DomWrapper';
import lazyLoad from '../../../LazyLoad';

/* eslint-disable camelcase */

const ANIMATION_DELAY = 50;
const ANIMATION_TIME = 2500;
const LABEL_CHANGE_TIME = 1000;

const viewProvider = getStateValue('ecommerce.viewProvider');

class SingleProduct {
  element = null;

  model = {};

  settings = {};

  constructor(element, model) {
    this.element = element;
    this.model = model;
    this.settings = element.dataset.settings ? JSON.parse(element.dataset.settings) : {};
    this.elBuyButton = dom.getElement('.buy-button', element);
    this.isDashStore = viewProvider === ECWID_DASH_PAGE_TYPE;
    this.isBackupMode = getStateValue('isBackup', false);
  }

  init = (index = 0) => {
    this.applyBackground(index);
    this.checkCart();
    this.initBuyButton();
    this.attachOpenProductPageHandler();
    this.initExcerpt();
    this.updateNativeHoverEffects();

    ecommerce.provider.cart.onChange.add(this.checkCart);
  };

  checkCart = async () => {
    const count = await ecommerce.provider.cart.itemGetQuantity(this.model.defaultId || this.model.id);

    if (count) {
      dom.addClass(this.element, '_added');
    } else {
      dom.removeClass(this.element, '_added');
    }

    this.updateButtonLabel();
  };

  initBuyButton = async () => {
    const {
      isNativeStore,
      buttonAction,
      productUrlFormat,
    } = this.settings;

    if (!this.elBuyButton || this.isBackupMode) return;

    this.isAdding = false;
    dom.on(this.elBuyButton, 'click', async (e) => {
      e.stopPropagation(); // prevent attachOpenProductPageHandler

      if (isNativeStore
        || (this.isDashStore && (this.model.checkWithOptions() || this.model.isSubscriptionAvailable))
      ) {
        router.goToProductPage(this.model.id, this.model.name, this.model.sku, productUrlFormat, this.isDashStore);

        return;
      }

      const count = await ecommerce.provider.cart.itemGetQuantity(this.model.defaultId || this.model.id);

      if (this.isAdding) return;
      if (this.model.cantAddToCartMore(count)) {
        router.goToCartPage(this.isDashStore);

        return;
      }
      if (!this.model.isAvailable()) {
        router.goToProductPage(this.model.id, this.model.name, this.model.sku, productUrlFormat, this.isDashStore);

        return;
      }

      const setAddingDelayed = debounce(() => {
        this.isAdding = true;
        dom.addClass(this.elBuyButton, 'btn_spinner');
      }, 100);

      const blink = () => {
        clearTimeout(this.blinkTimeout);
        dom.removeClass(this.elBuyButton, '_active');
        setTimeout(() => {
          dom.addClass(this.elBuyButton, '_active');
          this.labelChangeTimeout = setTimeout(() => {
            this.fixLabel = false;
            this.updateButtonLabel();
          }, LABEL_CHANGE_TIME);
          this.blinkTimeout = setTimeout(() => {
            dom.removeClass(this.elBuyButton, '_active');
          }, ANIMATION_TIME);
        }, ANIMATION_DELAY);
      };

      const addToCart = async (callback) => {
        setAddingDelayed();

        try {
          await ecommerce.provider.cart
            .itemAdd(this.model.defaultId || this.model.id, 1, {}, (isAdded) => {
              setTimeout(
                () => {
                  this.isAdding = false;
                  dom.removeClass(this.elBuyButton, 'btn_spinner');
                  blink();

                  if (!isAdded) return;

                  if (!dom.hasClass(this.element, '_added')) {
                    dom.addClass(this.element, '_added');
                  }

                  if (isFunction(callback)) callback();
                },
                this.isAdding ? 500 : 0
              );
              setAddingDelayed.cancel();
            });
        } catch (error) {
          setAddingDelayed.cancel();
          setTimeout(
            () => {
              this.isAdding = false;
              dom.removeClass(this.elBuyButton, 'btn_spinner');
              // TODO Show product adding error
            },
            this.isAdding ? 500 : 0
          );
          // eslint-disable-next-line no-console
          console.error(error);
        }
      };

      switch (buttonAction) {
        case OPEN_PRODUCT_PAGE: {
          router.goToProductPage(this.model.id, this.model.name, this.model.sku, productUrlFormat, this.isDashStore);

          break;
        }
        case ADD_TO_CART: {
          this.fixLabel = true;
          await addToCart();

          break;
        }
        case GO_TO_CHECKOUT: {
          this.fixLabel = true;
          await addToCart(() => router.goToCartPage(this.isDashStore));

          break;
        }
        default: {
          break;
        }
      }
    });
  };

  updateButtonLabel = async () => {
    if (this.fixLabel) return;

    clearTimeout(this.labelChangeTimeout);
    const { buttonLabels } = this.settings;

    if (!this.elBuyButton) return;

    const count = await ecommerce.provider.cart.itemGetQuantity(this.model.defaultId || this.model.id);
    let label;

    if (this.model.isOutOfStock()
      || (this.isDashStore && (this.model.checkWithOptions() || this.model.isSubscriptionAvailable))
    ) {
      label = buttonLabels.details;
    } else if (!this.model.cantAddToCartMore(count)) {
      label = buttonLabels.buy;
    } else {
      label = buttonLabels.checkout;
    }

    dom.addHtml(dom.getElement('.buy-text', this.elBuyButton), label);
  };

  applyBackground = (index) => {
    const { aspectRatio, product_list_image_aspect_ratio } = this.settings;
    const aspectRatioFloat = ASPECT_RATIO[aspectRatio || product_list_image_aspect_ratio];
    const images = dom.getCollection('.product__img > img', this.element);
    const backgroundColor = images && images.length > 0
      ? 'transparent'
      : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length];
    const imageContainerStyle = {
      backgroundColor,
      height: 0,
      paddingTop: `calc(100% / ${aspectRatioFloat})`,
    };

    dom.updateStyle(dom.getElement('.product__img', this.element), imageContainerStyle);

    const { colors } = this.settings;
    // eslint-disable-next-line no-nested-ternary
    const color = colors && colors.background && !lightness(colors.background)
      ? (isCssVar(colors.colorContrast) ? toValidColor(colors.colorContrast) : colors.colorContrast)
      : (isCssVar(colors.color) ? toValidColor(colors.color) : colors.color);

    dom.updateStyle(this.element, { color });

    // eslint-disable-next-line no-param-reassign
    if (browser.isIe()) [...images].forEach((image) => image.dataset.objectFit = 'cover');

    setTimeout(() => lazyLoad(), 0);
  };

  attachOpenProductPageHandler = () => {
    if (!this.element || this.isBackupMode) return;

    const { productUrlFormat } = this.settings;

    dom.on(this.element, 'click', () => {
      router.goToProductPage(this.model.id, this.model.name, this.model.sku, productUrlFormat, this.isDashStore);
    });
  };

  initExcerpt = () => {
    const elText = dom.getElement('._excerpt', this.element);

    if (!elText) return;

    const text = elText.textContent || '';

    if (!text) return;

    elText.innerHTML = text.length < 45 ? text : `${text.slice(0, 44)}...`;
    dom.showOpacity(elText);
  };

  updateNativeHoverEffects = () => {
    const {
      isNativeStore,
      hasPhoto,
      hasHoverName,
      hasHoverSubtitle,
      hasHoverSku,
      hasHoverPrice,
      hasHoverButton,
    } = this.settings;

    if (!isNativeStore || !hasPhoto) return;

    const onlyHoverSubtitleActive = (
      !hasHoverName
      && hasHoverSubtitle
      && !hasHoverSku
      && !hasHoverPrice
      && !hasHoverButton
    );
    const needRemoveEffectClass = onlyHoverSubtitleActive && !this.model.subtitle;

    if (!needRemoveEffectClass) return;

    // remove effect class name if only hasHoverSubtitle option active and subtitle field is empty in product model
    dom.removeClass(this.element, '_effect_subtitle');
    dom.removeClass(this.element, NATIVE_EFFECT_CLASS_NAME);
  };
}

export default SingleProduct;
