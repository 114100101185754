export default (payload) => {
  const form = document.createElement('form');

  form.setAttribute('method', 'post');
  form.setAttribute('action', 'https://www.paypal.com/cgi-bin/webscr');
  form.setAttribute('target', '_blank');
  form.setAttribute('style', 'display: none;');

  Object.keys(payload).forEach((key) => {
    const input = document.createElement('input');

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', payload[key]);
    form.append(input);
  });

  document.body.append(form);
  form.submit();
  form.remove();
};
