import dom from '../../wrapper/DomWrapper';
import { FACEBOOK_SELECTOR } from '../../constants';

class FacebookComments {
  constructor(element) {
    this.elFacebookComment = element || dom.getElement(FACEBOOK_SELECTOR);
  }

  init() {
    const fbComment = dom.getElement('.fb-comments', this.elFacebookComment);

    if (!fbComment) return;

    const widgetHref = fbComment.dataset.href;
    const isNormalizedHref = !!widgetHref;
    const location = window.location.href;

    if (!isNormalizedHref) {
      fbComment.dataset.href = location;
    }

    const mutationObserver = new MutationObserver((diff) => {
      if (diff[0].target.getAttribute('fb-xfbml-state') === 'rendered') {
        dom.removeClass(this.elFacebookComment, 'spinner');
      }
    });

    mutationObserver.observe(fbComment, { attributes: true });
  }
}

export default FacebookComments;
