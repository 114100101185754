import entries from 'lodash/entries';

import { getPreviousDeviceName, getSortedDevices } from '../../../helper/image';
import dom from '../../wrapper/DomWrapper';
import browser from '../browser';

class ImageHelper {
  constructor(elPicture) {
    this.elPicture = elPicture;
    this.isRetina = browser.isRetina();
  }

  init = () => {
    this.correctSourceForRetina();
  };

  correctSourceForRetina = () => {
    if (!this.isRetina) return;

    const sourceCollection = dom.getCollection('source[data-device]', this.elPicture) || [];
    const sourceList = [...sourceCollection];

    if (sourceList.length <= 1) return;

    const devices = sourceList.map((el) => el.dataset.device);
    const sortedDevices = getSortedDevices(devices);
    const reduceDevices = sortedDevices.reduce((acc, deviceName) => {
      const previousDeviceName = getPreviousDeviceName(deviceName, sortedDevices);

      if (!previousDeviceName) return acc;

      const elSource = sourceList.find((el) => el.dataset.device === deviceName) || {};
      const { srcset: prevSrcset } = sourceList.find((el) => el.dataset.device === previousDeviceName) || {};

      if (!elSource || !prevSrcset) return acc;

      return {
        ...acc,
        [deviceName]: prevSrcset,
      };
    }, {});

    entries(reduceDevices).forEach(([deviceName, srcset]) => {
      const elSource = dom.getElement(`source[data-device="${deviceName}"]`, this.elPicture);

      if (!elSource) return;

      elSource.srcset = srcset;
    });
  };
}

export default ImageHelper;
