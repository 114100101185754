const getVimeoDataByVideoId = async (id) => {
  const url = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`;

  try {
    const response = await fetch(url);

    if (response.status === 200 || response.status === 201) {
      const json = await response.json();

      return json;
    }

    return {};
  } catch {
    return {};
  }
};

export default getVimeoDataByVideoId;
