import { getVimeoDataByVideoId, getVimeoVideoId } from '../utils';

import ProviderWrapper from './Wrapper';

class VideoVimeo extends ProviderWrapper {
  init = async () => {
    const isVideoExists = this.renderVideoOnPage();

    if (isVideoExists) return;

    // Valid Vimeo video id should be numbers in string format like '314648479'
    const { id, isBackground, isSliderBg } = this.dataParams;
    const pattern = /^\d+$/;
    const isCorrectId = pattern.test(id);
    const prepareId = isCorrectId
      ? id
      : await getVimeoVideoId(id);

    this.player = this.connectVideoDefault();
    this.addPreviewImage(this.player);
    this.player.source = {
      type: 'video',
      sources: [
        {
          src: prepareId,
          provider: 'vimeo',
        },
      ],
    };

    this.player.elements.poster.style.display = 'none';

    this.player.elements.container?.addEventListener('ready', () => {
      if (isBackground || isSliderBg) {
        this.setSizeVideo(prepareId);
      } else {
        this.resizeVideo();
      }
    });
  };

  setSizeVideo = async (prepareId) => {
    const { width = 426, height = 240 } = await getVimeoDataByVideoId(prepareId);
    const iframe = this.getIframe();

    iframe.height = height;
    iframe.width = width;

    this.resizeVideo();
  };
}

export default VideoVimeo;
