import isString from 'lodash/isString';

const SVG_MIME_TYPES = new Set(['image/svg', 'image/svg+xml']);

/**
 * Util for check if is image type is SVG
 * @param {string} src
 * @param {string=} contentType
 * @returns {boolean}
 */
export default (src, contentType) => {
  if (contentType) {
    return SVG_MIME_TYPES.has(contentType);
  }

  if (!isString(src)) return false;

  return src.toLowerCase().includes('.svg');
};
