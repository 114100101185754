import isArray from 'lodash/isArray';
import isElement from 'lodash/isElement';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import noop from 'lodash/noop';

import { camelToKebab } from '../../helper/stringFormater';

import { FADE_INTERVAL, MIN_FADE_DURATION } from './constants';

const DomWrapper = {
  /**
   * @return {Window}
   */
  get window() {
    if (!window) throw new Error('Wrong environment, window not found');

    return window;
  },

  set window(value) {
    throw new Error('window is read only property');
  },

  /**
   * @return {Document}
   */
  get document() {
    if (!document) throw new Error('Wrong environment, document not found');

    return document;
  },

  set document(value) {
    throw new Error('document is read only property');
  },

  /**
   *
   * @returns {number}
   */
  get windowHeight() {
    const cH = DomWrapper.document.documentElement.clientHeight;
    const wH = DomWrapper.window.innerHeight || 0;

    return Math.max(cH, wH);
  },

  /**
   *
   * @returns {number}
   */
  get bodyHeight() {
    return Math.max(
      DomWrapper.document.body.scrollHeight,
      DomWrapper.document.body.offsetHeight,
      DomWrapper.document.body.getBoundingClientRect().height,
      DomWrapper.document.documentElement.clientHeight,
      DomWrapper.document.documentElement.scrollHeight,
      DomWrapper.document.documentElement.offsetHeight
    );
  },

  /**
   *
   * @returns {number}
   */
  get documentScrollTop() {
    return DomWrapper.document.documentElement.scrollTop;
  },

  /**
   * Function for prepare selector for another using
   * @param {string} selector
   * @returns {string}
   */
  prepareSelector: (selector) => {
    const SELECTOR_SEPARATORS = /(?=\.)|(?=\[)|(?=>)|(?=\s)|(?=\+)|(?=~)|(?=#)(?!\S*["'|])/gm;

    const selectorsList = selector.split(SELECTOR_SEPARATORS);

    return selectorsList.reduce((acc, chunk) => {
      const formattedChunk = chunk.startsWith('#')
        ? `[id="${chunk.replace('#', '')}"]`
        : chunk;

      return acc + formattedChunk;
    }, '');
  },

  /**
   * @param {string} name
   * @param {HTMLElement|Element|Document} parent
   * @returns {HTMLCollection}
   */
  getCollection(name, parent = DomWrapper.document) {
    if (!parent || !name) return null;

    return parent.querySelectorAll(DomWrapper.prepareSelector(name));
  },

  /**
   * @param {string} name
   * @param {HTMLElement|Element|Document} parent
   * @returns {HTMLElement}
   */
  getElement(name, parent = DomWrapper.document) {
    if (!parent || !name) return null;

    return parent.querySelector(DomWrapper.prepareSelector(name));
  },

  /**
   * @param {HTMLElement|Element|ChildNode} element
   * @param {string} className
   * @return {boolean}
   */
  hasClass(element, className) {
    if (!element || !element.classList || !className) return false;

    return element.classList.contains(className);
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {string} className
   */
  addClass(element, className) {
    if (
      !element
      || !element.classList
      || !className
      || DomWrapper.hasClass(element, className)
    ) return;

    element.classList.add(className);
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {(string|Array)} className
   */
  removeClass(element, className) {
    if (
      !element
      || !element.classList
      || !className
      || (!isArray(className) && !DomWrapper.hasClass(element, className))
    ) return;

    if (isArray(className)) {
      className.forEach((el) => element.classList.remove(el));
    } else {
      element.classList.remove(className);
    }
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {string} className
   */
  toggleClass(element, className) {
    if (!isElement(element) || !element.classList || !className) return;

    element.classList.toggle(className);
  },

  /**
   * @param {HTMLElement|Element|string} element
   * @param {Object} styles
   */
  // eslint-disable-next-line consistent-return
  updateStyle(element, styles) {
    if (!element || (isElement(element) && !element.style)) return null;

    const domElement = isString(element)
      ? DomWrapper.getElement(element)
      : element;

    Object.keys(styles).forEach((property) => {
      const propertyName = camelToKebab(property);

      domElement.style.setProperty(propertyName, styles[property]);
    });
  },

  /**
   * @param {HTMLElement|Element} container
   * @return {number}
   */
  getElementWidth: (container) => (container ? container.offsetWidth : 0),

  /**
   * @param {HTMLElement|Element} container
   * @return {number}
   */
  getElementHeight: (container) => (container ? container.offsetHeight : 0),

  /**
   * @param {HTMLElement|Element} [element]
   * @returns {boolean}
   */
  hasVerticalScroll(element) {
    const documentComputedStyles = DomWrapper.window
      .getComputedStyle(DomWrapper.document.body, '');

    const mayHasScroll = documentComputedStyles.overflow === 'visible'
      || documentComputedStyles.overflowY === 'visible'
      || documentComputedStyles.overflow === 'auto'
      || documentComputedStyles.overflowY === 'auto';

    if (!mayHasScroll) return false;
    if (isElement(element)) return element.scrollHeight > element.offsetHeight;

    return DomWrapper.window.innerHeight
      ? DomWrapper.document.body.offsetHeight > DomWrapper.window.innerHeight
      : DomWrapper.document.documentElement.scrollHeight
      > DomWrapper.document.documentElement.offsetHeight
      || DomWrapper.document.body.scrollHeight
      > DomWrapper.document.body.offsetHeight;
  },

  /**
   * @param {HTMLElement|Element|ChildNode} element
   * @param {string} text
   */
  addText(element, text) {
    if (
      !isElement(element)
      || !isString(text)
      // eslint-disable-next-line unicorn/prefer-dom-node-text-content
      || element.innerText === text
    ) return;

    // eslint-disable-next-line no-param-reassign, unicorn/prefer-dom-node-text-content
    element.innerText = text;
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {string} html as string
   */
  addHtml(element, html) {
    if (!isElement(element) || !isString(html)) return;

    // eslint-disable-next-line no-param-reassign
    element.innerHTML = html;
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {Object} event
   * @param {function} fn
   */
  on(element, event, fn) {
    if (!element
      || !isFunction(element.addEventListener)
      || !isString(event)
      || !isFunction(fn)) return;

    element.addEventListener(event, fn);
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {Object} event
   * @param {function} fn
   */
  off(element, event, fn) {
    if (!element
      || !isFunction(element.addEventListener)
      || !isString(event)
      || !isFunction(fn)) return;

    element.removeEventListener(event, fn);
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {string} event
   * @param {Object} params
   */
  trigger(element, event, params = {}) {
    if (!isElement(element)
        || (!event || !isString(event))
        || (!params || !isObject(params))) return;

    const customEvent = new CustomEvent(event, { detail: params });

    element.dispatchEvent(customEvent);
  },

  /**
   * @param {string} type
   * @param {Object} options
   * @returns {HTMLElement | null}
   */
  createElement(type, options = {}) {
    const {
      id = null,
      className,
      // eslint-disable-next-line unicorn/prefer-dom-node-text-content
      innerText,
    } = options;

    try {
      const element = DomWrapper.document.createElement(type);

      if (id && isString(id)) element.setAttribute('id', id);
      if (className && isString(className)) {
        const classNamesList = className.split(' ');

        classNamesList.forEach((currentClassName) => {
          DomWrapper.addClass(element, currentClassName);
        });
      }
      if (innerText && isString(innerText)) {
        DomWrapper.addText(element, innerText);
      }

      return element;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);

      return null;
    }
  },

  /**
   * @param {string} variable
   * @returns {string}
   */
  getCssVar: (variable) => DomWrapper.window
    .getComputedStyle(DomWrapper.document.documentElement)
    .getPropertyValue(variable),

  /**
   * @param {HTMLElement|Element} element
   */
  removeElement(element) {
    if (!isElement(element) || !isElement(element.parentNode)) return;

    element.remove();
  },

  /**
   * @param {HTMLElement|Element} element
   */
  show(element) {
    if (!isElement(element)) return;

    this.removeClass(element, 'hidden');
  },

  /**
   * @param {HTMLElement|Element} element
   */
  hide(element) {
    if (!isElement(element)) return;

    this.addClass(element, 'hidden');
  },

  /**
   * @param {HTMLElement|Element} element
   */
  showOpacity(element) {
    if (!isElement(element)) return;

    this.updateStyle(element, { opacity: 1 });
  },

  /**
   * @param {HTMLElement|Element} element
   */
  hideOpacity(element) {
    if (!isElement(element)) return;

    this.updateStyle(element, { opacity: 0 });
  },

  /**
   * @param {HTMLElement|Element} element
   */
  toggleVisibility(element) {
    if (!isElement(element)) return;

    const isHidden = this.hasClass(element, 'hidden');

    if (isHidden) {
      this.removeClass(element, 'hidden');
    } else {
      this.addClass(element, 'hidden');
    }
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {Number} duration
   * @param {Function} callback
   * @returns {Number | null}
   */
  fadeInJs(element, duration = MIN_FADE_DURATION, callback) {
    if (!isElement(element)) return null;

    let opacity = 0.01;
    const fadeDuration = isNumber(duration) && duration >= MIN_FADE_DURATION
      ? duration
      : MIN_FADE_DURATION;
    const opacityStep = FADE_INTERVAL / fadeDuration;

    this.updateStyle(element, { opacity });
    this.show(element);

    const timer = setInterval(() => {
      if (opacity >= 1) {
        clearInterval(timer);

        if (isFunction(callback)) callback();
      }

      opacity += opacityStep;

      this.updateStyle(element, { opacity });
    }, FADE_INTERVAL);

    return timer;
  },

  /**
   * @param {HTMLElement|Element} element
   * @param {Number} duration
   * @param {Function} callback
   * @returns {Number | null}
   */
  fadeOutJs(element, duration = MIN_FADE_DURATION, callback) {
    if (!isElement(element)) return null;

    let opacity = 1;
    const fadeDuration = isNumber(duration) && duration >= MIN_FADE_DURATION
      ? duration
      : MIN_FADE_DURATION;
    const opacityStep = FADE_INTERVAL / fadeDuration;

    this.updateStyle(element, { opacity });

    const timer = setInterval(() => {
      if (opacity <= 0) {
        clearInterval(timer);
        this.hide(element);
        if (isFunction(callback)) callback();
      }

      opacity -= opacityStep;

      this.updateStyle(element, { opacity });
    }, FADE_INTERVAL);

    return timer;
  },

  /**
   * @param {HTMLElement|Element} element
   */
  fadeIn(element, ANIMATION_TIME = 300, callback = noop) {
    if (!isElement(element)) return;

    this.removeClass(element, '_fade-out');
    this.addClass(element, '_fade-in');

    setTimeout(() => {
      this.addClass(element, '_show');
      this.removeClass(element, '_fade-in');
      callback();
    }, ANIMATION_TIME);
  },

  /**
   * @param {HTMLElement|Element} element
   */
  fadeOut(element, ANIMATION_TIME = 300, callback = noop) {
    if (!isElement(element)) return;

    this.addClass(element, '_fade-out');
    this.removeClass(element, '_show');

    setTimeout(() => {
      this.removeClass(element, '_fade-out');
      callback();
    }, ANIMATION_TIME);
  },
};

export default DomWrapper;
