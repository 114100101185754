const STATUS_TEXT = {
  isOutOfStock: 'sh.wf.ecom_product.status-outOfStock',
  isOnSale: 'sh.wf.ecom_product.on_sale',
  isDigital: 'sh.wf.ecom_product.digital',
  isFewLeft: 'sh.wf.ecom_product.few_left',
};

const STATUS_CLASS = {
  isOutOfStock: 'label--outOfStock',
  isOnSale: 'label--notice',
  isDigital: 'label--digital',
  isFewLeft: 'label--fewLeft',
};

/**
 *
 * @param {boolean=} isNativeStore
 * @param {Object} statuses
 * @param {boolean} statuses.isOutOfStock
 * @param {boolean} statuses.isOnSale
 * @param {boolean} statuses.isDigital
 * @param {boolean} statuses.isFewLeft
 * @param {function} getTranslate
 * @returns {null|[{
 *   key: string,
 *   text: string,
 *   className: string
 * }]}
 */
export default (isNativeStore = false, statuses = {}, getTranslate) => {
  const {
    isOutOfStock,
    isOnSale,
    isDigital,
    isFewLeft,
  } = statuses;
  const showLabel = isOutOfStock || isOnSale;

  if (!showLabel && !isDigital && !isFewLeft) return null;

  const badges = [];

  if (showLabel) {
    const statusKey = Object.keys(STATUS_TEXT).find((key) => statuses[key]);
    const text = STATUS_TEXT[statusKey];

    badges.push({
      key: statusKey,
      text: getTranslate(text),
      className: STATUS_CLASS[statusKey] || '',
    });
  }

  if (!isNativeStore && isDigital) {
    badges.push({
      key: 'digital',
      text: getTranslate(STATUS_TEXT.isDigital),
      className: STATUS_CLASS.isDigital,
    });
  }

  if (!isNativeStore && !isDigital && isFewLeft) {
    badges.push({
      key: 'fewLeft',
      text: getTranslate(STATUS_TEXT.isFewLeft),
      className: STATUS_CLASS.isFewLeft,
    });
  }

  return badges;
};
