import { lightness, toValidColor } from '../../../helpers/color';
import { isCssVar } from '../../../helpers/color/utils';

/**
 *
 * @param {Object} options
 * @param {string} options.color
 * @param {string=} options.background
 * @param {string} options.colorContrast
 * @returns {string}
 */
const getColor = ({
  color,
  background,
  colorContrast,
}) => (background && !lightness(background)
  ? (isCssVar(colorContrast) ? toValidColor(colorContrast) : colorContrast)
  : (isCssVar(color) ? toValidColor(color) : color));

export default getColor;
