export const POST_PAGE_WIDTH_DICT = {
  narrow: 8,
  standard: 10,
  wide: 12,
};

export const BANNER_HEIGHT_DICT = {
  640: 'tall',
  410: 'medium',
  310: 'short',
};

export const POST_FORMAT = {
  HERO: 'hero',
  STANDARD: 'standard',
};

export const BLOCK_TYPE = {
  IMAGE: 'image',
  IMAGE_BLOCK: 'image_block',
  IMAGE_CAPTION: 'image_caption',
  LIST_BLOCK: 'list',
  LIST_ITEM: 'list_item',
  PARAGRAPH: 'paragraph',
  SPACE: 'space',
  DIVIDER: 'divider',
  VIDEO: 'video',
  HEADING: 'heading',
  LINK: 'link',
  BLOCKQUOTE1: 'blockquote1',
  BLOCKQUOTE2: 'blockquote2',
};

export const DEFAULT_CHILDREN = [{ text: '' }];
