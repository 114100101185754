import EcwidProvider from './infrastructure/ecwidProvider';
import DashProvider from './infrastructure/dashProvider';
import getStateValue from '../../../helpers/getStateValue';
import { ECWID_DASH_PAGE_TYPE } from '../../../../components/Ecommerce/constants';

class Ecommerce {
  constructor(providerType) {
    switch (providerType) {
      case ECWID_DASH_PAGE_TYPE:
        this.provider = new DashProvider();

        break;
      default:
        this.provider = new EcwidProvider();
    }
  }
}

export default new Ecommerce(getStateValue('ecommerce.viewProvider'));
