import { lightness } from '../../../helpers/color';
import dom from '../../../wrapper/DomWrapper';

const checkIsDarkBg = (el) => {
  if (!el) return false;

  const { backgroundColor } = dom.window.getComputedStyle(el);

  if (!backgroundColor) return false;

  return !lightness(backgroundColor);
};

export const checkContrastBg = (elForm) => {
  const elParentSection = elForm?.closest('.s-section');
  const elParentShape = elForm?.closest('.section__shape');
  const elParentBg = elParentShape
    ? dom.getElement('.section_bg', elParentShape)
    : elParentSection;
  const isDarkParentBg = checkIsDarkBg(elParentBg);

  if (!isDarkParentBg) return;

  dom.addClass(elForm, 'is-dark');
};
