import isValid from 'date-fns/isValid';

const stringToDate = (date, format) => {
  const formatItems = format.split('/');
  const dateItems = date.split('/');
  const monthIndex = formatItems.indexOf('mm');
  const dayIndex = formatItems.indexOf('dd');
  const yearIndex = formatItems.indexOf('yyyy');
  let month = dateItems[monthIndex];

  month -= 1;

  return new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
};

export default (value) => value === '' || isValid(stringToDate(value, 'mm/dd/yyyy')) || isValid(stringToDate(value, 'dd/mm/yyyy'));
