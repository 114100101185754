import { DESKTOP, LAPTOP, QUAD } from '../../../device';

export const IMG_BG_DEFAULT = {
  url: '',
  transform: {},
};
export const IMG_BG_CLASS_NAME = 'js-img-bg-picture';

export const BG_DEVICE_SIZES = { // also used in blog
  [LAPTOP]: [0, 500],
  [DESKTOP]: [501, 1500],
  [QUAD]: [1501, null],
};

export const QUAD_LAYOUT = `image_${QUAD}`;
export const DESKTOP_LAYOUT = `image_${DESKTOP}`;
export const LAPTOP_LAYOUT = `image_${LAPTOP}`;

export const DEFAULT_TRANSFORM_LAYOUT = QUAD_LAYOUT;
export const TRANSFORM_LAYOUTS = [QUAD_LAYOUT, DESKTOP_LAYOUT, LAPTOP_LAYOUT];
