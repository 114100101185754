import isEmpty from 'lodash/isEmpty';

import dom from '../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import LightBox from '../../Lightbox/LightBox';
import { LIGHT_BOX_SELECTOR } from '../../Photo/constants';

class PostImage extends WidgetWrapper {
  init = (parent = dom.document) => {
    const elPostImagesList = dom.getCollection(this.selector, parent);

    if (isEmpty(elPostImagesList)) return;

    elPostImagesList.forEach((photo = {}) => {
      const { widgetId = null, settings } = photo.dataset;

      if (widgetId && settings) {
        const settingsJson = JSON.parse(settings);

        if (settingsJson.hasLightbox) this.getLightBox().photoModalData[widgetId] = settingsJson;

        this.connectPhotoToLightBox(widgetId);
      }
    });
  };

  getLightBox() {
    if (!this.lightBox) this.lightBox = new LightBox(LIGHT_BOX_SELECTOR);

    return this.lightBox;
  }

  connectPhotoToLightBox = (photoId) => {
    const lightBox = this.getLightBox();
    const { modalsParams } = lightBox.getLightBoxAttributes();

    const photoToConnect = photoId
      ? { [photoId]: lightBox.photoModalData[photoId] }
      : lightBox.photoModalData;

    lightBox.addLightBoxToPhotos(
      photoToConnect,
      modalsParams
    );
  };
}

export default PostImage;
