import has from 'lodash/has';

import getImageResizeUrl from '../../../helpers/getImageResizeUrl';

const getStaticImageResizeUrl = require('../../../helpers/getStaticImageResizeUrl');

/**
 *
 * @param {string} src
 * @param {string} layout
 * @param {object} dynamic
 * @param {object} options
 * @returns {string} resizeSrc
 */
const generatePictureResizeSrc = (src, layout = '', dynamic, options) => {
  const { hash, subDir, transform = {} } = dynamic || {};
  const { apiUrls, staticData } = options || {};
  const { imageResizeApiService } = apiUrls || {};
  const { staticDir, isStaticPublished } = staticData || {};

  if (!has(transform, layout)) return src;
  if (isStaticPublished) return getStaticImageResizeUrl(hash, layout, { staticDir, subDir });

  return getImageResizeUrl(src, layout, dynamic, imageResizeApiService);
};

export default generatePictureResizeSrc;
