import { DEVICE_PRIORITY } from './constants';

/**
 *
 * @param {string[]} devices
 * @returns {string[]} sortedDevices
 */
const getSortedDevices = (devices) => devices.sort((prev, next) => {
  const prevIndex = DEVICE_PRIORITY.indexOf(prev);
  const nextIndex = DEVICE_PRIORITY.indexOf(next);

  if (prevIndex === -1 || nextIndex === -1) return 0;

  return prevIndex - nextIndex;
});

export default getSortedDevices;
