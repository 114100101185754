import { IN_VIEW_CLASS_NAME, IN_VIEW_ENTER_CLASS_NAME } from '../../../../../constants';
import checkProjectWithAnimation from '../../../../helpers/checkProjectWithAnimation';
import { connectInView } from '../../../../helpers/inView';
import dom from '../../../../wrapper/DomWrapper';

/**
 *
 * @param {HTMLElement} elContainer
 */
const mountItemsInView = (elContainer) => {
  const withAnimation = checkProjectWithAnimation();

  if (!withAnimation) return;

  const elements = dom.getCollection(`.gallery-item.${IN_VIEW_CLASS_NAME}`, elContainer) || [];
  const filterElements = [...elements].filter((el) => !dom.hasClass(el, IN_VIEW_ENTER_CLASS_NAME));

  if (filterElements.length === 0) return;

  setTimeout(() => {
    connectInView(filterElements);
  }, 0);
};

export default mountItemsInView;
