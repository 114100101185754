/**
 *
 * @param {string} href
 * @returns {number|null}
 */
const getServiceId = (href) => {
  const idPartRegex = /\d+(\/?)+(\?|#|$)/gm;

  if (href.search(idPartRegex) === -1) return null;

  const idPart = href.match(idPartRegex)[0];
  const productId = idPart.replace(/[#/?p]/gi, '');

  return Number.parseInt(productId, 10) || null;
};

export default getServiceId;
